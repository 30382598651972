import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { FC, useState } from 'react';
import { DateTime } from 'luxon';
import {
  CreateTrainingMutationVariables,
  IntervalEnum,
  TrailLiteracyTrainingPreview,
  User,
} from '../../__generated__/gql/graphql';
import { Button } from '../../components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import { defineStepper } from '../../components/ui/stepper';
import BasicInfoStep from './steps/BasicInfoStep';
import MaterialUploadStep from './steps/MaterialUploadStep';
import ReviewStep from './steps/ReviewStep';
import QuestionsStep from './steps/QuestionsStep';

const CREATE_TRAINING = gql`
  mutation CreateTraining(
    $title: String!
    $description: String!
    $responsiblePersonId: String!
    $interval: IntervalEnum!
    $isRequired: Boolean!
    $dueDate: DateTime
    $existingMaterialId: String
    $fileId: String
    $sections: [TrainingMaterialSectionInput!]
    $questions: [TrainingQuestionInput!]
  ) {
    createTraining(
      title: $title
      description: $description
      responsiblePersonId: $responsiblePersonId
      interval: $interval
      isRequired: $isRequired
      dueDate: $dueDate
      existingMaterialId: $existingMaterialId
      fileId: $fileId
      sections: $sections
      questions: $questions
    ) {
      training {
        id
        title
        description
        questions {
          questionText
          description
          options {
            optionText
            isCorrect
          }
        }
        trainingMaterialSections {
          title
          material {
            id
            fileName
            url
          }
        }
        responsiblePerson {
          id
          name
          email
        }
        isRequired
        interval
        trainingMaterial {
          id
          fileName
          url
        }
        dueDate
        completionLogs {
          id
          user {
            id
            name
            email
          }
          completionDate
        }
        lastModified
        created
      }
    }
  }
`;

const STEPPER_CONFIG = [
  {
    id: 'basic-info',
    title: 'Basic Information',
    description: 'Enter training details',
  },
  {
    id: 'material-upload',
    title: 'Training Material',
    description: 'Upload or select material',
  },
  {
    id: 'questions',
    title: 'Questions',
    description: 'Add training questions',
  },
  {
    id: 'review',
    title: 'Review',
    description: 'Review your training',
  },
] as const;

const {
  StepperProvider,
  StepperNavigation,
  StepperStep,
  StepperTitle,
  StepperDescription,
  StepperPanel,
  StepperControls,
} = defineStepper(...STEPPER_CONFIG);

interface TrainingCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  allUser: User[];
  availableTrainingMaterials: TrailLiteracyTrainingPreview[];
  hasLiteracyAccess: boolean;
}

export interface FormValues {
  title: string;
  description: string;
  responsiblePersonId: string;
  interval: IntervalEnum;
  isRequired: boolean;
  dueDate?: DateTime;
  existingMaterialId?: string;
  fileName?: string;
  fileContent?: string;
  questions?: {
    questionText: string;
    description?: string;
    options: {
      optionText: string;
      isCorrect: boolean;
    }[];
  }[];
  uploadMethod: 'new' | 'existing' | 'sections';
  sections: Array<{
    id: number;
    title: string;
    file: File | null;
    fileId: string | null;
  }>;
}

const DEFAULT_FORM_VALUES: FormValues = {
  title: '',
  description: '',
  responsiblePersonId: '',
  interval: IntervalEnum.Yearly,
  isRequired: false,
  questions: [],
  uploadMethod: 'new',
  sections: [],
};

const TrainingCreationModal: FC<TrainingCreationModalProps> = ({
  isOpen,
  onClose,
  allUser,
  availableTrainingMaterials,
  hasLiteracyAccess,
}) => {
  const [selectedExistingMaterial, setSelectedExistingMaterial] = useState<
    string | null
  >(null);
  const [fileId, setFileId] = useState<string | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const methods = useForm<FormValues>({
    defaultValues: DEFAULT_FORM_VALUES,
  });

  const formValues = useWatch({ control: methods.control }) as FormValues;

  const [createTraining] = useMutation(CREATE_TRAINING, {
    onCompleted: () => {
      onClose();
      toast.success('Training created successfully');
    },
    onError: error => {
      toast.error(`Error creating training: ${error.message}`);
    },
    update: (cache, { data }) => {
      if (!data?.createTraining?.training) return;

      cache.modify({
        fields: {
          allLiteracyTrainings(existingTrainings = []) {
            const newTrainingRef = cache.writeFragment({
              data: data.createTraining.training,
              fragment: gql`
                fragment NewTraining on LiteracyTraining {
                  id
                  title
                  description
                  responsiblePerson {
                    id
                    name
                    email
                  }
                  isRequired
                  interval
                  trainingMaterial {
                    id
                    fileName
                    url
                  }
                  trainingMaterialSections {
                    title
                    material {
                      id
                      fileName
                      url
                    }
                  }
                  dueDate
                  completionLogs {
                    id
                    user {
                      id
                      name
                      email
                    }
                    completionDate
                  }
                  lastModified
                  created
                  __typename
                }
              `,
            });
            return [...existingTrainings, newTrainingRef];
          },
        },
      });
    },
  });

  const handleCreate = async () => {
    try {
      if (!isBasicInfoValid(formValues)) {
        toast.error('Please fill in all required fields');
        return;
      }
      const formattedSections = formValues.sections.map(section => ({
        title: section.title,
        fileId: section.fileId,
      }));

      const variables: CreateTrainingMutationVariables = {
        title: formValues.title,
        description: formValues.description,
        responsiblePersonId: formValues.responsiblePersonId,
        interval: formValues.interval,
        isRequired: formValues.isRequired,
        dueDate: formValues.dueDate,
        ...(selectedExistingMaterial && {
          existingMaterialId: selectedExistingMaterial,
        }),
        ...(fileId && { fileId }),
        questions: formValues.questions,
        sections:
          formValues.uploadMethod === 'sections'
            ? formattedSections
            : undefined,
      };

      await createTraining({ variables });
    } catch (error) {
      console.error('Error creating training:', error);
      toast.error('Failed to create training');
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[70%] max-w-7xl h-[85vh] overflow-auto flex flex-col gap-14 pb-0">
        <DialogHeader>
          <DialogTitle>Create a new Training</DialogTitle>
        </DialogHeader>
        <StepperProvider className="h-full flex flex-col">
          {({ methods: stepperMethods }) => (
            <div className="flex flex-col h-full gap-8">
              <StepperNavigation>
                {STEPPER_CONFIG.map(step => (
                  <StepperStep key={step.id} of={step.id}>
                    <StepperTitle>{step.title}</StepperTitle>
                    <StepperDescription>{step.description}</StepperDescription>
                  </StepperStep>
                ))}
              </StepperNavigation>
              <div className="flex-1">
                <FormProvider {...methods}>
                  {stepperMethods.switch({
                    'basic-info': () => (
                      <StepperPanel>
                        <BasicInfoStep allUsers={allUser} />
                      </StepperPanel>
                    ),
                    'material-upload': () => (
                      <StepperPanel>
                        <MaterialUploadStep
                          selectedFile={selectedFile}
                          selectedExistingMaterial={selectedExistingMaterial}
                          onFileSelected={setSelectedFile}
                          onFileIdSelected={setFileId}
                          onExistingMaterialSelected={
                            setSelectedExistingMaterial
                          }
                          availableTrainingMaterials={
                            availableTrainingMaterials || []
                          }
                          hasLiteracyAccess={hasLiteracyAccess}
                        />
                      </StepperPanel>
                    ),
                    questions: () => (
                      <StepperPanel>
                        <QuestionsStep />
                      </StepperPanel>
                    ),
                    review: () => (
                      <StepperPanel>
                        <ReviewStep
                          formValues={formValues}
                          selectedFile={selectedFile}
                          selectedExistingMaterial={availableTrainingMaterials?.find(
                            m =>
                              m.trainingMaterial?.id ===
                              selectedExistingMaterial
                          )}
                        />
                      </StepperPanel>
                    ),
                  })}
                </FormProvider>
              </div>
              <StepperControls className="pb-6">
                {!stepperMethods.isFirst && (
                  <Button variant="outline" onClick={stepperMethods.prev}>
                    Back
                  </Button>
                )}
                {stepperMethods.isLast ? (
                  <Button
                    onClick={handleCreate}
                    disabled={
                      !isStepValid(
                        stepperMethods.current.id,
                        formValues,
                        selectedFile,
                        selectedExistingMaterial
                      )
                    }
                  >
                    Create Training
                  </Button>
                ) : (
                  <Button
                    onClick={stepperMethods.next}
                    disabled={
                      !isStepValid(
                        stepperMethods.current.id,
                        formValues,
                        selectedFile,
                        selectedExistingMaterial
                      )
                    }
                  >
                    Next
                  </Button>
                )}
              </StepperControls>
            </div>
          )}
        </StepperProvider>
      </DialogContent>
    </Dialog>
  );
};

const isBasicInfoValid = (formValues: Partial<FormValues>): boolean => {
  return !!(
    formValues.title &&
    formValues.description &&
    formValues.responsiblePersonId &&
    formValues.dueDate
  );
};

const isStepValid = (
  stepId: string,
  formValues: FormValues,
  selectedFile: File | null,
  selectedExistingMaterial: string | null
): boolean => {
  switch (stepId) {
    case 'basic-info':
      return isBasicInfoValid(formValues);
    case 'material-upload':
      if (formValues.uploadMethod === 'new') {
        return !!selectedFile;
      } else if (formValues.uploadMethod === 'existing') {
        return !!selectedExistingMaterial;
      } else {
        return (
          formValues.sections.length > 0 &&
          formValues.sections.every(section => section.title && section.fileId)
        );
      }
    case 'questions':
      return isQuestionsValid(formValues.questions);
    default:
      return true;
  }
};

const isQuestionsValid = (questions?: FormValues['questions']): boolean => {
  if (!questions?.length) return false;

  return questions.every(question => {
    const hasCorrectAnswer = question.options.some(option => option.isCorrect);
    const allOptionsHaveText = question.options.every(option =>
      option.optionText.trim()
    );
    return (
      question.questionText.trim() && hasCorrectAnswer && allOptionsHaveText
    );
  });
};

export default TrainingCreationModal;
