import { FC } from 'react';
import PdfViewer from '../../components/PdfViewer';
import { pdfjs } from 'react-pdf';
import { Loader2 } from 'lucide-react';
import {
  Maybe,
  TrainingMaterialSectionType,
} from '@/__generated__/gql/graphql';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PdfViewerScreenProps {
  isLoading: boolean;
  loadingPdf: boolean;
  pdfData?: string;
  currentPage: number;
  onPageLoad: (numPages: number) => void;
  sections?: Maybe<TrainingMaterialSectionType>[];
  currentSection?: number;
}

const PdfViewerScreen: FC<PdfViewerScreenProps> = ({
  isLoading,
  loadingPdf,
  pdfData,
  currentPage,
  onPageLoad,
  sections,
  currentSection = 0,
}) => {
  if (sections && sections.length > 0 && currentPage === 0) {
    return (
      <div className="flex flex-col w-full h-full items-center justify-center">
        <h1>{sections[currentSection]?.title}</h1>
        <p className="mt-4 text-gray-600">
          Press the next button to start this section.
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col w-full h-full">
      <div className="relative flex-grow">
        {isLoading || loadingPdf ? (
          <div className="flex justify-center items-center h-full">
            <Loader2 className="h-8 w-8 animate-spin" />
          </div>
        ) : !pdfData ? (
          <div className="flex justify-center items-center h-full">
            <p className="text-destructive">Error loading PDF</p>
          </div>
        ) : (
          <div className="h-full rounded-xl bg-background">
            <PdfViewer
              pdfData={pdfData}
              currentPage={currentPage}
              onPageLoad={onPageLoad}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PdfViewerScreen;
