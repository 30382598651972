import type React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import QuestionItem from './QuestionItem';
import { FormValues } from '../TrainingCreationModal';
import { Plus } from 'lucide-react';

const QuestionsStep: React.FC = () => {
  const { control } = useFormContext<FormValues>();
  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
  } = useFieldArray({
    control,
    name: 'questions',
  });

  const addQuestion = () => {
    appendQuestion({
      questionText: '',
      description: '',
      options: [
        { optionText: '', isCorrect: false },
        { optionText: '', isCorrect: false },
      ],
    });
  };
  return (
    <div className="max-w-5xl mx-auto">
      <Card className="border-0 shadow-none">
        <CardHeader>
          <CardTitle className="text-2xl font-semibold text-center">
            Create Your Training Questions
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          {questionFields.map((question, qIndex) => (
            <QuestionItem
              key={question.id}
              qIndex={qIndex}
              onRemove={removeQuestion}
            />
          ))}
          <Button
            onClick={addQuestion}
            className="w-full h-12 mt-4 border-2 border-dashed
                     hover:border-yellow-500"
            variant="outline"
          >
            <Plus className="w-5 h-5 mr-2" />
            Add New Question
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default QuestionsStep;
