import { FC, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import {
  GetFileContentQuery,
  LiteracyTraining,
} from '../__generated__/gql/graphql';
import HorizontalLinearStepper from '../sign_up/HorizontalLinearStepper';
import NavigationButton from '../components/NavigationButton';
import { getCurrentStep, getStepLabels } from '../utils/navigationHelpers';
import WelcomeScreen from './training_modal_screens/WelcomeScreen';
import PdfViewerScreen from './training_modal_screens/PdfViewScreen';
import CompletionScreen from './training_modal_screens/CompletionScreen';
import QuestionsScreen from './training_modal_screens/QuestionScreen';
import FailureScreen from './training_modal_screens/FailureScreen';
import { Dialog, DialogContent, DialogPortal } from '@/components/ui/dialog';

const GET_FILE_CONTENT = gql`
  query GetFileContent($versionedFileId: String!) {
    versionedFile(versionedFileId: $versionedFileId) {
      id
      contentBase64
    }
  }
`;

const COMPLETE_TRAINING = gql`
  mutation CompleteTraining($trainingId: String!) {
    completeTraining(trainingId: $trainingId) {
      trainingCompletion {
        id
        completionDate
        user {
          id
          name
        }
        training {
          id
          title
        }
      }
    }
  }
`;

interface TrainingModalProps {
  open: boolean;
  onClose: () => void;
  training: LiteracyTraining | null;
}

enum TrainingStage {
  Welcome = 'WELCOME',
  PdfViewer = 'PDF_VIEWER',
  Questions = 'QUESTIONS',
  Completion = 'COMPLETION',
  Failure = 'FAILURE',
}

enum AssessmentResult {
  None = 'NONE',
  Pass = 'PASS',
  Fail = 'FAIL',
}

const TrainingModal: FC<TrainingModalProps> = ({ open, onClose, training }) => {
  const hasSections = !!training?.trainingMaterialSections?.length;
  const [activeStep, setActiveStep] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [result, setResult] = useState<AssessmentResult>(AssessmentResult.None);
  const [currentSection, setCurrentSection] = useState(0);

  const sections = training?.trainingMaterialSections;
  const versionedFileId =
    sections && sections.length > 0
      ? sections[currentSection]?.material?.id
      : training?.trainingMaterial?.id;

  const { data, loading } = useQuery<GetFileContentQuery>(GET_FILE_CONTENT, {
    variables: versionedFileId ? { versionedFileId } : undefined,
    skip: !versionedFileId || activeStep === 0,
    onError: () => {
      toast.error('Error loading training material');
    },
  });

  const [completeTraining] = useMutation(COMPLETE_TRAINING, {
    onCompleted: () => {
      toast.success('Training completed successfully');
      onClose();
    },
    onError: error => {
      toast.error(`Failed to complete training: ${error.message}`);
    },
    update: (cache, { data }) => {
      const completedTraining = data?.completeTraining?.trainingCompletion;
      if (!completedTraining) return;

      cache.modify({
        id: cache.identify({
          __typename: 'LiteracyTraining',
          id: completedTraining.training.id,
        }),
        fields: {
          currentUserCompletion() {
            return {
              __typename: 'TrainingCompletion',
              id: completedTraining.id,
              completionDate: completedTraining.completionDate,
            };
          },
        },
      });
    },
  });

  const handlePdfLoad = (numPages: number) => {
    setTotalPages(numPages);
  };

  const getCurrentStage = () => {
    switch (activeStep) {
      case 0:
        return TrainingStage.Welcome;
      case 1:
        return TrainingStage.PdfViewer;
      case 2:
        if (result === AssessmentResult.Pass) return TrainingStage.Completion;
        if (result === AssessmentResult.Fail) return TrainingStage.Failure;
        if (training?.questions?.length) return TrainingStage.Questions;
        return TrainingStage.Completion;
      default:
        return TrainingStage.Welcome;
    }
  };

  const handleNavigation = (direction: 'next' | 'back') => {
    if (result !== AssessmentResult.None) return;

    if (activeStep === 0 && direction === 'next') {
      setActiveStep(1);
      setCurrentPage(0);
      return;
    }

    if (activeStep === 1) {
      if (hasSections) {
        if (direction === 'next') {
          if (currentPage === 0) {
            setCurrentPage(1);
          } else if (currentPage >= totalPages) {
            if (
              currentSection <
              (training?.trainingMaterialSections?.length || 0) - 1
            ) {
              setCurrentSection(prev => prev + 1);
              setCurrentPage(0);
              setTotalPages(1);
            } else {
              setActiveStep(2);
            }
          } else {
            setCurrentPage(prev => prev + 1);
          }
        } else {
          if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
          } else if (currentPage === 1) {
            setCurrentPage(0);
          } else if (currentPage === 0) {
            if (currentSection > 0) {
              setCurrentSection(prev => prev - 1);
              setCurrentPage(totalPages);
            } else {
              setActiveStep(0);
            }
          }
        }
      } else {
        if (direction === 'next') {
          if (currentPage >= totalPages) {
            setActiveStep(2);
          } else {
            setCurrentPage(prev => prev + 1);
          }
        } else {
          if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
          } else {
            setActiveStep(0);
          }
        }
      }
    }
  };

  const resetStates = () => {
    setActiveStep(0);
    setCurrentPage(0);
    setTotalPages(1);
    setResult(AssessmentResult.None);
    setCurrentSection(0);
  };

  const handleClose = () => {
    resetStates();
    onClose();
  };

  const handleComplete = async () => {
    if (training?.id) {
      await completeTraining({
        variables: { trainingId: training.id },
      });
    }
    resetStates();
  };

  const handleRetry = () => {
    setResult(AssessmentResult.None);
    resetStates();
  };

  const stages: Record<TrainingStage, JSX.Element> = {
    [TrainingStage.Welcome]: (
      <WelcomeScreen training={training} onStart={() => setActiveStep(1)} />
    ),
    [TrainingStage.PdfViewer]: (
      <PdfViewerScreen
        isLoading={loading}
        loadingPdf={loading}
        pdfData={data?.versionedFile?.contentBase64 ?? undefined}
        currentPage={currentPage}
        onPageLoad={handlePdfLoad}
        sections={training?.trainingMaterialSections ?? undefined}
        currentSection={currentSection}
      />
    ),
    [TrainingStage.Questions]: (
      <QuestionsScreen
        training={training}
        onComplete={() => setResult(AssessmentResult.Pass)}
        onFail={() => setResult(AssessmentResult.Fail)}
      />
    ),
    [TrainingStage.Completion]: (
      <CompletionScreen training={training} onComplete={handleComplete} />
    ),
    [TrainingStage.Failure]: <FailureScreen onRetry={handleRetry} />,
  };

  const getStepperConfig = () => {
    const hasQuestions = !!training?.questions?.length;
    const isComplete = result !== AssessmentResult.None;

    return {
      step: getCurrentStep(
        activeStep,
        currentSection,
        hasSections,
        hasQuestions,
        isComplete
      ),
      stepLabels: getStepLabels({
        hasSections,
        sections: training?.trainingMaterialSections || [],
        hasQuestions,
        totalPages,
      }),
    };
  };

  return (
    <Dialog
      open={open}
      onOpenChange={isOpen => {
        if (!isOpen) {
          handleClose();
        }
      }}
    >
      <DialogPortal>
        <DialogContent className="p-8 flex w-[80%] h-[85%] justify-between overflow-hidden">
          <div className="w-full flex h-full gap-8 items-center">
            {getCurrentStage() !== TrainingStage.Questions &&
              result === AssessmentResult.None && (
                <div className="w-10 h-full flex items-center">
                  <NavigationButton
                    direction="back"
                    onClick={() => handleNavigation('back')}
                    show={activeStep > 0 || currentPage > (hasSections ? 0 : 1)}
                  />
                </div>
              )}

            <div className="flex h-full flex-col items-center flex-grow gap-10 w-full overflow-hidden">
              <div className="flex-shrink-0 mb-2 w-full">
                <div className="w-full overflow-x-auto">
                  <HorizontalLinearStepper
                    {...getStepperConfig()}
                    stepperProps={{
                      sx: {
                        minWidth: 'max-content',
                        padding: '16px',
                        '& .MuiStepLabel-label': {
                          color: 'white',
                          fontSize: '0.875rem',
                        },
                        '& .MuiStepIcon-root': {
                          fontSize: '1.25rem',
                        },
                      },
                    }}
                  />
                </div>
              </div>

              <div className="w-full overflow-auto h-full">
                {stages[getCurrentStage()]}
              </div>
            </div>

            {getCurrentStage() !== TrainingStage.Questions &&
              result === AssessmentResult.None && (
                <div className="w-10 h-full flex items-center">
                  <NavigationButton
                    direction="next"
                    onClick={() => handleNavigation('next')}
                    show={activeStep !== 0}
                  />
                </div>
              )}
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default TrainingModal;
