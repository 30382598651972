import { useParams } from 'react-router-dom';
import ProjectIntegrations from '../project_view/ProjectIntegrations';
import { Card, CardContent } from '@/components/ui/card';

export const Integrations = () => {
  const { projectId } = useParams();

  return (
    <Card>
      <CardContent>
        <h4>Integrations</h4>
        {projectId && <ProjectIntegrations projectID={projectId} />}
      </CardContent>
    </Card>
  );
};
