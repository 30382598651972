import { Box, TableCell, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC } from 'react';
import { IntervalData } from '../../utils/generateIntervals';
import {
  Maybe,
  TrainingCompletion,
  User,
} from '../../__generated__/gql/graphql';
import { ProgressBar } from '../../components/ProgressBar';
import { Button } from '../../components/ui/button';
import { ChevronUp, ChevronDown } from 'lucide-react';

interface CompletionProgressProps {
  interval: IntervalData;
  completionLogs: Maybe<Maybe<TrainingCompletion>[]> | undefined;
  users: User[];
  open: boolean;
}

const CompletionProgress: FC<CompletionProgressProps> = ({
  interval,
  completionLogs = [],
  users,
  open,
}) => {
  const completions =
    completionLogs?.filter(log => {
      if (!log?.completionDate) return false;
      const completionDate = DateTime.fromISO(log.completionDate);
      return completionDate >= interval.start && completionDate < interval.end;
    }) || [];

  const completionPercentage = (completions.length / users.length) * 100;

  return (
    <>
      <TableCell>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <ProgressBar variant="determinate" value={completionPercentage} />
            <Typography variant="caption" color="text.secondary">
              {completions.length} of {users.length} completed (
              {Math.round(completionPercentage)}%)
            </Typography>
          </Box>
          <Button size="sm" variant={'ghost'}>
            {open ? <ChevronUp /> : <ChevronDown />}
          </Button>
        </Box>
      </TableCell>
    </>
  );
};

export default CompletionProgress;
