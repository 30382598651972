import React, { FC } from 'react';
import { DateTime } from 'luxon';
import {
  Calendar,
  CheckCircle,
  Upload,
  FileText,
  Text,
  Clock,
} from 'lucide-react';
import {
  IntervalEnum,
  TrailLiteracyTrainingPreview,
} from '../../../__generated__/gql/graphql';
import { FormValues } from '../TrainingCreationModal';
import { Card } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';

interface ReviewStepProps {
  formValues: FormValues;
  selectedFile: File | null;
  selectedExistingMaterial?: TrailLiteracyTrainingPreview;
}

const ReviewStep: FC<ReviewStepProps> = ({
  formValues,
  selectedFile,
  selectedExistingMaterial,
}) => {
  const formatInterval = (interval: IntervalEnum) => {
    return interval.charAt(0) + interval.slice(1).toLowerCase();
  };
  return (
    <div className="flex flex-col gap-6 max-w-3xl mx-auto">
      <Card className="overflow-hidden">
        <div className="divide-y">
          <ReviewItem icon={<Text />} label="Title" value={formValues.title} />
          <ReviewItem
            icon={<FileText />}
            label="Description"
            value={formValues.description}
          />
          <ReviewItem
            icon={<Clock />}
            label="Training Schedule"
            value={formatInterval(formValues.interval)}
          />
          {formValues.dueDate && (
            <ReviewItem
              icon={<Calendar />}
              label="Due Date"
              value={formValues.dueDate.toLocaleString(DateTime.DATE_FULL)}
            />
          )}
          <div className="p-4">
            <div className="flex items-center gap-4">
              <CheckCircle className="text-muted-foreground" />
              <div className="flex-1">
                <p className="text-sm text-muted-foreground">Training Status</p>
                <Badge
                  variant={formValues.isRequired ? 'default' : 'outline'}
                  className={`mt-2 ${
                    formValues.isRequired ? 'text-black' : 'text-white'
                  }`}
                >
                  {formValues.isRequired ? 'Required' : 'Optional'}
                </Badge>
              </div>
            </div>
          </div>
          {(selectedFile || selectedExistingMaterial) && (
            <>
              <div className="p-4">
                <div className="flex items-center gap-4">
                  <Upload className="text-muted-foreground" />
                  <div className="flex-1">
                    <p className="font-medium">
                      <span>
                        {selectedExistingMaterial ? '' : 'Training Material:'}{' '}
                      </span>
                      {selectedExistingMaterial
                        ? `Using existing material: ${selectedExistingMaterial.previewTitle}`
                        : selectedFile
                        ? selectedFile.name
                        : 'No material selected'}
                    </p>
                    {selectedFile && (
                      <Badge variant="outline" className="mt-2">
                        {`${(selectedFile.size / 1024 / 1024).toFixed(2)} MB`}
                      </Badge>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Card>
    </div>
  );
};

const ReviewItem: FC<{
  icon: React.ReactNode;
  label: string;
  value: string;
}> = ({ icon, label, value }) => (
  <div className="p-4">
    <div className="flex items-center gap-4">
      {React.cloneElement(icon as React.ReactElement, {
        className: 'text-muted-foreground',
      })}
      <div className="flex-1">
        <p className="text-sm text-muted-foreground">{label}</p>
        <p className="mt-1 text-sm">{value}</p>
      </div>
    </div>
  </div>
);

export default ReviewStep;
