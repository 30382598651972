import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Button } from '../../components/ui/button';
interface ProjectTypeStageProps {
  onClick: () => void;
}

export default function ProjectTypeStage({ onClick }: ProjectTypeStageProps) {
  const { setValue } = useFormContext();

  function onClickHandler(isPurchased: boolean) {
    setValue('isPurchased', isPurchased);
    onClick();
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <h2>Is this model purchased or internally built?</h2>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            marginTop: '2rem',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Controller
            name="isPurchased"
            render={({ field }) => (
              <Button
                variant={'outline'}
                className="h-22 w-30 break-words whitespace-normal"
                onClick={() => onClickHandler(true)}
                {...field}
              >
                Purchased
              </Button>
            )}
          />
          <Controller
            name="isPurchased"
            render={({ field }) => (
              <Button
                variant={'outline'}
                className="h-22 w-30 break-words whitespace-normal"
                onClick={() => onClickHandler(false)}
                {...field}
              >
                Internally Built
              </Button>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
}
