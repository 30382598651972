import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Typography,
  Tooltip,
  Autocomplete,
  ListItemText,
  Chip,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { colorThemes } from '../theme';
import { countries, getCountryCode } from 'countries-list';

export default function ExternalContextStage() {
  const { register, control } = useFormContext();

  const {
    fields: fieldsNorms,
    append: appendNorm,
    remove: removeNorm,
  } = useFieldArray({
    control,
    name: 'valueNorms',
  });
  const addFieldNorm = () => {
    appendNorm({ organizationValue: '', description: '' });
  };

  const {
    fields: fieldsAssociatedPolicies,
    append: appendAssociatedPolicies,
    remove: removeAssociatedPolicies,
  } = useFieldArray({
    control,
    name: 'associatedPolicies',
  });
  const addFieldAssociatedPolicy = () => {
    appendAssociatedPolicies('');
  };

  const {
    fields: fieldsExternalParties,
    append: appendExternalParty,
    remove: removeExternalParty,
  } = useFieldArray({
    control,
    name: 'externalParties',
  });
  const addFieldParty = () => {
    appendExternalParty({ name: '', requirements: '' });
  };

  const buttonStyle = {
    color: 'white',
    fontWeight: 'bold',
    gap: '4px',
    paddingRight: '1rem',
    backgroundColor: colorThemes.ACTION_BLUE,
  };

  const countryList: string[] = Object.values(countries).map(
    country => country.name
  );

  return (
    <>
      <Typography variant="h6" marginBottom={'5px'} fontWeight={600}>
        Regulations
      </Typography>
      <Grid container>
        <Grid item xs={5} sx={{ marginTop: '5px' }}>
          <Controller
            name="countries"
            control={control}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={countryList}
                  value={value || []}
                  onChange={(_, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Countries"></TextField>
                  )}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return (
                        <Chip
                          icon={
                            <img
                              loading="eager"
                              width="20px"
                              style={{ marginLeft: '1rem' }}
                              srcSet={`https://flagcdn.com/w40/${(
                                getCountryCode(option) as string
                              ).toLowerCase()}.png 2x`}
                              src={`https://flagcdn.com/w20/${(
                                getCountryCode(option) as string
                              ).toLowerCase()}.png`}
                              alt=""
                            />
                          }
                          variant="filled"
                          style={{ backgroundColor: colorThemes.DARK_BLUE_800 }}
                          label={option}
                          key={key}
                          {...tagProps}
                        />
                      );
                    })
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <img
                        loading="eager"
                        width="20px"
                        style={{ marginRight: '10px' }}
                        srcSet={`https://flagcdn.com/w40/${(
                          getCountryCode(option) as string
                        ).toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${(
                          getCountryCode(option) as string
                        ).toLowerCase()}.png`}
                        alt=""
                      />
                      <ListItemText primary={option} />
                    </li>
                  )}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item sm={8} sx={{ marginTop: '1rem' }}>
          <Button sx={buttonStyle} onClick={addFieldAssociatedPolicy}>
            <AddIcon />
            Add Regulation
          </Button>
        </Grid>
        {fieldsAssociatedPolicies.map((field, index) => (
          <Grid container spacing={2} key={field.id} marginTop={'1px'}>
            <Grid item xs={10}>
              <TextField
                id="outlined-basic"
                label="Regulation"
                variant="outlined"
                {...register(`associatedPolicies.${index}`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton
                onClick={() => removeAssociatedPolicies(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Typography
        variant="h6"
        marginTop={'1.5rem'}
        marginBottom={'5px'}
        fontWeight={600}
      >
        Values & Norms
      </Typography>
      <Button sx={buttonStyle} onClick={addFieldNorm}>
        <AddIcon />
        Add Value/Norm
      </Button>
      <Grid container>
        {fieldsNorms.map((field, index) => (
          <Grid container spacing={2} key={field.id} marginTop={'5px'}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Value/Norm"
                variant="outlined"
                aria-placeholder={'Value/Norm'}
                {...register(`valueNorms.${index}.organizationValue`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                aria-placeholder={
                  'Describe this value/norm in the context of your organization'
                }
                {...register(`valueNorms.${index}.description`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton
                onClick={() => removeNorm(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ position: 'relative', marginTop: '1rem' }}>
        <Tooltip
          title={externalContentTooltipText}
          sx={{ position: 'absolute', marginLeft: '-25px' }}
        >
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
        <TextField
          id="outlined-basic"
          label="Other External Context"
          variant="outlined"
          multiline
          rows={6}
          {...register('externalContext')}
          sx={{ width: '100%' }}
        />
      </Box>
      <Typography
        variant="h6"
        marginTop={'1.5rem'}
        marginBottom={'5px'}
        fontWeight={600}
      >
        External Parties
      </Typography>
      <Button sx={buttonStyle} onClick={addFieldParty}>
        <AddIcon />
        Add Party
      </Button>
      <Grid container>
        {fieldsExternalParties.map((field, index) => (
          <Grid container spacing={2} key={field.id} marginTop={'5px'}>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Party"
                variant="outlined"
                aria-placeholder={'Party'}
                {...register(`externalParties.${index}.name`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={7}>
              <TextField
                id="outlined-basic"
                label="Requirements"
                variant="outlined"
                {...register(`externalParties.${index}.requirements`)}
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={1} sm={1}>
              <IconButton
                onClick={() => removeExternalParty(index)}
                sx={{ height: '100%' }}
              >
                <ClearIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

const externalContentTooltipText =
  'Describe the other external context such as:' +
  '\n\nPolicies, guidelines and decisions from regulators that have an impact on the interpretation or enforcement of legal requirements in the development and use of AI systems.\n\n' +
  'Under preparation. Stage at the time of publication: ISO/IEC DIS 5259-1:2023.\n\n' +
  'Incentives or consequences associated with the intended purpose and the use of AI systems.\n\n' +
  'Culture, traditions, values, norms, and ethics with respect to development and use of AI.\n\n' +
  'Competitive landscape and trends for new products and services using AI systems.';
