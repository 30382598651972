import { FC, useState } from 'react';
import { LiteracyTraining } from '../../__generated__/gql/graphql';
import { Button } from '../../components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';

interface QuestionsScreenProps {
  training: LiteracyTraining | null;
  onComplete: () => void;
  onFail: () => void;
}

interface Answer {
  questionIndex: number;
  selectedOptionIndex: number;
}

const QuestionsScreen: FC<QuestionsScreenProps> = ({
  training,
  onComplete,
  onFail,
}) => {
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const totalQuestions = training?.questions?.length || 0;

  const selectedValue =
    answers
      .find(a => a.questionIndex === currentQuestionIndex)
      ?.selectedOptionIndex?.toString() || '';

  const handleAnswerSelect = (value: string) => {
    setAnswers(prev => {
      const newAnswers = [...prev];
      const existingAnswerIndex = newAnswers.findIndex(
        a => a.questionIndex === currentQuestionIndex
      );

      if (existingAnswerIndex >= 0) {
        newAnswers[existingAnswerIndex].selectedOptionIndex = Number(value);
      } else {
        newAnswers.push({
          questionIndex: currentQuestionIndex,
          selectedOptionIndex: Number(value),
        });
      }

      return newAnswers;
    });
  };

  const handleQuestionChange = (newIndex: number) => {
    setCurrentQuestionIndex(newIndex);
  };

  const calculateScore = () => {
    let correctAnswers = 0;
    answers.forEach(answer => {
      const question = training?.questions?.[answer.questionIndex];
      if (question?.options?.[answer.selectedOptionIndex]?.isCorrect) {
        correctAnswers++;
      }
    });
    return (correctAnswers / totalQuestions) * 100;
  };

  const handleSubmit = () => {
    const score = calculateScore();
    if (score >= 70) {
      onComplete();
    } else {
      onFail();
    }
  };

  const currentQuestion = training?.questions?.[currentQuestionIndex];

  return (
    <div className="flex flex-col h-full max-w-5xl mx-auto">
      <Card className="flex-grow border-2">
        <CardContent className="p-6 space-y-6">
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium">
                Question {currentQuestionIndex + 1}
              </h3>
              {answers.length} of {totalQuestions} answered
            </div>
            <p className="text-xl">{currentQuestion?.questionText}</p>
            {currentQuestion?.description && (
              <p className="text-muted-foreground text-sm">
                {currentQuestion.description}
              </p>
            )}
          </div>

          <RadioGroup
            value={selectedValue}
            onValueChange={handleAnswerSelect}
            className="space-y-3"
          >
            {currentQuestion?.options?.map((option, index) => (
              <Label
                key={`q${currentQuestionIndex}-option-${index}`}
                htmlFor={`q${currentQuestionIndex}-option-${index}`}
                className="flex items-center space-x-3 p-4 border rounded-lg hover:bg-accent cursor-pointer transition-colors"
              >
                <RadioGroupItem
                  value={index.toString()}
                  id={`q${currentQuestionIndex}-option-${index}`}
                  className="data-[state=checked]:border-primary"
                />
                <span className="text-base">{option?.optionText}</span>
              </Label>
            ))}
          </RadioGroup>
          <div className="flex justify-between mt-6">
            <Button
              variant="outline"
              disabled={currentQuestionIndex === 0}
              onClick={() => handleQuestionChange(currentQuestionIndex - 1)}
            >
              Previous
            </Button>

            {currentQuestionIndex < totalQuestions - 1 ? (
              <Button
                disabled={
                  !answers.some(a => a.questionIndex === currentQuestionIndex)
                }
                onClick={() => handleQuestionChange(currentQuestionIndex + 1)}
              >
                Next
              </Button>
            ) : (
              <Button
                disabled={answers.length < totalQuestions}
                onClick={handleSubmit}
              >
                Submit Assessment
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default QuestionsScreen;
