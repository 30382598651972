import { useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ChevronLeft } from 'lucide-react';

import {
  AllowedDataTypeEnum,
  AllowedTaskTypeEnum,
  DataTypeEnum,
  ItAsset,
  OrganizationDepartmentsQuery,
  ProjectTypeEnum,
  RiskClass,
  UsersBasicInfoQuery,
} from '../../__generated__/gql/graphql';
import { GET_PROJECTS } from '../ProjectView';

import ProjectTypeStage from './ProjectTypeStage';
import ModelTypeStage from './ModelTypeStage';
import ModelSpesificationStage from './ModelSpesificationStage';
import RiskClassification from '../../risk_classification/RiskClassification';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Button } from '../../components/ui/button';

const CREATE_PROJECT = gql(`
  mutation CreateProject(
    $title: String!,
    $description: String,
    $department: String,
    $is_purchased: Boolean,
    $project_type: ProjectTypeEnum,
    $stage: StageEnum,
    $responsible: String,
    $allowed_data_types: [AllowedDataTypeEnum],
    $allowed_task_types: [AllowedTaskTypeEnum],
    $usage_policy: String,
    $steps_description: String,
    $risk_class: RiskClassInput
    $data_type: DataTypeEnum,
    $users: String,
    $it_asset: String,
    $operation_entry_date: DateTime,
  ) {
    createProject(
      title: $title,
      description: $description,
      department: $department,
      isPurchased: $is_purchased,
      projectType: $project_type,
      stage: $stage,
      responsible: $responsible,
      allowedDataTypes: $allowed_data_types,
      allowedTaskTypes: $allowed_task_types,
      usagePolicy: $usage_policy,
      stepDescription: $steps_description,
      riskClass: $risk_class
      dataType: $data_type,
      users: $users,
      itAsset: $it_asset,
      operationEntryDate: $operation_entry_date,
    ) {
      project {
        id
      }
    }
  }
`);

enum StageTypes {
  ProjectType,
  ModelType,
  ModelSpecificationStage,
  RiskClassQuestionnaire,
}

export interface FormValues {
  isPurchased: boolean | null;
  purchasedModel: ProjectTypeEnum;
  title: string;
  description: string;
  responsiblePerson: string;
  department: string;
  usagePolicy: string;
  allowedDataTypes: AllowedDataTypeEnum[];
  allowedTaskTypes: AllowedTaskTypeEnum[];
  stage: string | null;
  stepsDescription: string;
  operationEntryDate: Date | null;
  itAsset: string;
  dataType: string;
  users: string;
}

interface ProjectCreationModalProps {
  isOpen: boolean;
  onClose: () => void;
  userData: UsersBasicInfoQuery;
  departmentsData: OrganizationDepartmentsQuery;
  allItAssets: NonNullable<ItAsset>[];
}

export default function ProjectCreationModal({
  isOpen,
  onClose,
  userData,
  departmentsData,
  allItAssets,
}: ProjectCreationModalProps) {
  const formSchema = {
    isPurchased: null,
    purchasedModel: ProjectTypeEnum.Custom,
    title: '',
    description: '',
    responsiblePerson: '',
    department: '',
    usagePolicy: '',
    allowedDataTypes: [],
    allowedTaskTypes: [],
    stage: null,
    stepsDescription: '',
    operationEntryDate: null,
    itAsset: '',
    dataType: DataTypeEnum.NotSpecified,
    users: '',
  };

  const methods = useForm<FormValues>({
    defaultValues: formSchema,
  });
  const { control, getValues } = methods;

  const [stage, setStage] = useState<StageTypes>(StageTypes.ProjectType);
  const [riskClass, setRiskClass] = useState<RiskClass | undefined>();

  const [createProject] = useMutation(CREATE_PROJECT, {
    refetchQueries: [{ query: GET_PROJECTS }],
    onCompleted: () => {
      onClose();
      toast.success('Project created successfully');
    },
    onError: () => {
      toast.error('Error creating project');
    },
  });

  const title = useWatch({ control, name: 'title' });
  const description = useWatch({ control, name: 'description' });
  const responsiblePerson = useWatch({ control, name: 'responsiblePerson' });
  const department = useWatch({ control, name: 'department' });

  const isLastPage = stage === StageTypes.ModelSpecificationStage;

  function onNextProjectSelection() {
    if (methods.getValues('isPurchased')) {
      setStage(StageTypes.ModelType);
    } else {
      setStage(StageTypes.ModelSpecificationStage);
    }
  }

  function onNextModelSelection() {
    setStage(StageTypes.ModelSpecificationStage);
  }

  function onNextRiskClassQuestionnaire() {
    setStage(StageTypes.RiskClassQuestionnaire);
  }

  function nextStepRiskClass() {
    setStage(StageTypes.ModelSpecificationStage);
  }

  function navigateBack() {
    switch (stage) {
      case StageTypes.ModelType:
        setStage(StageTypes.ProjectType);
        break;
      case StageTypes.ModelSpecificationStage:
        if (methods.getValues('isPurchased')) {
          setStage(StageTypes.ModelType);
        } else {
          setStage(StageTypes.ProjectType);
        }
        break;
      case StageTypes.RiskClassQuestionnaire:
        setStage(StageTypes.ModelSpecificationStage);
        break;
      default:
        break;
    }
  }

  function handleCreateProject() {
    const projectData = {
      title: getValues('title'),
      description: getValues('description'),
      department: getValues('department'),
      is_purchased: getValues('isPurchased'),
      project_type: getValues('purchasedModel'),
      stage: getValues('stage'),
      responsible: getValues('responsiblePerson'),
      allowed_data_types: getValues('allowedDataTypes'),
      allowed_task_types: getValues('allowedTaskTypes'),
      usage_policy: getValues('usagePolicy'),
      steps_description: getValues('stepsDescription'),
      risk_class: riskClass,
      data_type: getValues('dataType'),
      users: getValues('users'),
      it_asset: getValues('itAsset'),
      operation_entry_date: getValues('operationEntryDate'),
    };

    createProject({ variables: projectData });
  }

  const isCreateButtonDisabled = !(
    title &&
    description &&
    responsiblePerson &&
    department
  );

  function getDisabledReason() {
    if (!title) return 'Title is required';
    if (!description) return 'Description is required';
    if (!responsiblePerson) return 'Responsible person is required';
    if (!department) return 'Department is required';
    return '';
  }

  const stages: Record<StageTypes, JSX.Element> = {
    [StageTypes.ProjectType]: (
      <ProjectTypeStage onClick={onNextProjectSelection} />
    ),
    [StageTypes.ModelType]: <ModelTypeStage onClick={onNextModelSelection} />,
    [StageTypes.ModelSpecificationStage]: (
      <ModelSpesificationStage
        usersData={userData}
        departmentsData={departmentsData}
        onNextRiskClassQuestionnaire={onNextRiskClassQuestionnaire}
        riskClass={riskClass}
        allItAssets={allItAssets}
      />
    ),
    [StageTypes.RiskClassQuestionnaire]: (
      <RiskClassification
        setRiskClass={setRiskClass}
        nextStepRiskClass={nextStepRiskClass}
      />
    ),
  };

  return (
    <Dialog open={isOpen} onOpenChange={open => !open && onClose()}>
      <DialogContent className="h-[85vh] xl-[60%] w-[80%] p-5">
        <DialogHeader>
          <DialogTitle>Create a new project</DialogTitle>
        </DialogHeader>
        <div className="relative flex-1 overflow-y-auto">
          <FormProvider {...methods}>{stages[stage]}</FormProvider>
        </div>
        <div className="flex w-full items-center justify-between pt-4">
          {stage !== StageTypes.ProjectType && (
            <Button variant="outline" onClick={navigateBack}>
              <ChevronLeft className="mr-2 h-4 w-4" />
              Back
            </Button>
          )}
          {isLastPage && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <span>
                    <Button
                      onClick={handleCreateProject}
                      disabled={isCreateButtonDisabled}
                    >
                      Create Project
                    </Button>
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  {isCreateButtonDisabled ? getDisabledReason() : ''}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
