import { useSuspenseQuery, gql } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import ProjectDetailCard from './ProjectDetailCard';
import ProjectCreationModal from './project_creation_modal/ProjectCreationModal.react';
import { useProject } from '../contexts/project';
import { useSearchParams } from 'react-router-dom';
import {
  AllProjectsForProjectViewQuery,
  GetItAssetsQuery,
  ItAsset,
  OrganizationDepartmentsQuery,
  Project,
  RiskClass,
  User,
  UsersBasicInfoQuery,
} from '../__generated__/gql/graphql';
import ProjectDetailDrawerSuspenseWrapper from './ProjectDetailDrawerSuspenseWrapper';
import { ToastContainer } from 'react-toastify';
import SearchIcon from '@mui/icons-material/Search';
import ProjectFilter, {
  filterProjects,
  ProjectBaseFilter,
  ProjectFilterProps,
} from './ProjectFilter';
import CardGrid from '../components/CardGrid';
import { ProjectImportModal } from './project_creation_modal/ProjectImportModal';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { Button } from '../components/ui/button';

export const GET_PROJECTS = gql(`
  query AllProjectsForProjectView {
    allProjects {
      id
      title
      description
      allowedDataTypes
      allowedTaskTypes
      department
      isPurchased
      riskClass {
        riskClassCategory
        riskClassRole
      }
      stage
      responsible {
        id
        name
        email
        initials
      }
    }
  }
`);

export const GET_USERS = gql(`
  query UsersBasicInfo{
  allUsers{
    id
    name
    email
  }
}
`);

const GET_DEPARTMENTS = gql(`
  query OrganizationDepartments {
    organization {
      id
      departments
    }
  }`);

export const GET_IT_ASSETS = gql`
  query GetItAssets {
    allItAssets {
      id
      name
      created
      lastModified
    }
  }
`;

export default function ProjectView() {
  const { data: projectsData } =
    useSuspenseQuery<AllProjectsForProjectViewQuery>(GET_PROJECTS);
  useState<ProjectFilterProps>(ProjectBaseFilter);

  const { data: userData } = useSuspenseQuery<UsersBasicInfoQuery>(GET_USERS);
  const { data: departmentsData } =
    useSuspenseQuery<OrganizationDepartmentsQuery>(GET_DEPARTMENTS);

  const [params] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [projectFilters, setProjectFilters] =
    useState<ProjectFilterProps>(ProjectBaseFilter);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | undefined>();

  const { project: currentlyActiveProject, setProject } = useProject();

  const selectedProjectDetailsForParam = params.get('projectDetailsFor');
  const projectFromParam =
    projectsData?.allProjects?.find(
      p => p?.id === selectedProjectDetailsForParam
    ) || undefined;

  useEffect(() => {
    if (projectFromParam) {
      setSelectedProject(projectFromParam as Project);
      setShowSidebar(true);
    }
  }, [projectFromParam]);

  const [importModalOpen, setImportModalOpen] = useState(false);

  const showBetaFeatures = useFeatureFlagEnabled('beta-tester');

  const { data: itAssetsData } =
    useSuspenseQuery<GetItAssetsQuery>(GET_IT_ASSETS);

  const filteredItAssets =
    itAssetsData?.allItAssets?.filter(
      (asset): asset is ItAsset => asset !== null
    ) ?? [];

  // Derived state
  const filteredProjects = (projectsData?.allProjects?.filter(project => {
    if (!project) return false;
    return (
      project.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
      filterProjects({
        responsible: project.responsible?.id,
        stage: project.stage,
        department: project.department,
        riskClass: project.riskClass?.riskClassCategory,
        isPurchased: project.isPurchased,
        filters: projectFilters,
      })
    );
  }) ?? []) as Project[];

  const createDefaultUser = (): User => ({
    id: '',
    name: 'Not Defined',
    email: '',
    initials: '',
  });

  const formatRiskClass = (riskClass: RiskClass | null | undefined): string => {
    if (!riskClass || !riskClass.riskClassCategory) return 'Not Defined';
    return riskClass.riskClassCategory;
  };
  return (
    <Box>
      <Typography variant="h4" marginBottom={3}>
        AI Registry
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
          gap: '12px',
          alignItems: 'center',
        }}
      >
        <TextField
          id="search"
          sx={{ width: 700 }}
          onChange={e => setSearchTerm(e.target.value)}
          placeholder="Search for a project ..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <ProjectFilter
          projectFilters={projectFilters}
          setProjectFilters={setProjectFilters}
          userData={userData}
          departmentsData={departmentsData}
        />
        {showBetaFeatures && (
          <Button onClick={() => setImportModalOpen(true)} variant="outline">
            Import Data
          </Button>
        )}
      </Box>

      <CardGrid
        items={filteredProjects}
        title={
          filteredProjects.length === 0
            ? 'No Project'
            : `${filteredProjects.length} ${
                filteredProjects.length === 1 ? 'Project' : 'Projects'
              }`
        }
        renderCard={(projectData: Project) => (
          <ProjectDetailCard
            key={projectData.id}
            id={projectData.id}
            department={projectData.department ?? 'Not Defined'}
            stage={projectData.stage}
            user={projectData.responsible ?? createDefaultUser()}
            riskClass={formatRiskClass(projectData.riskClass)}
            onClick={() => {
              setSelectedProject(projectData);
              setShowSidebar(!showSidebar);
            }}
            isPurchased={projectData.isPurchased ?? false}
            title={projectData.title}
            description={projectData.description ?? ''}
            isSelected={currentlyActiveProject?.id === projectData.id}
          />
        )}
        onAddNew={() => setShowCreationModal(true)}
      />

      {/* Modals and Drawers */}
      {showCreationModal && (
        <ProjectCreationModal
          isOpen={showCreationModal}
          onClose={() => setShowCreationModal(false)}
          userData={userData}
          departmentsData={departmentsData}
          allItAssets={filteredItAssets}
        />
      )}

      <ProjectImportModal
        isOpen={importModalOpen}
        onOpenChange={setImportModalOpen}
      />

      {selectedProject && (
        <ProjectDetailDrawerSuspenseWrapper
          key={selectedProject.id}
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          selectedProject={selectedProject}
          setProject={setProject}
          allItAssets={filteredItAssets}
        />
      )}

      <ToastContainer />
    </Box>
  );
}
