import { FC, useState } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { CheckCircle } from 'lucide-react';
import { LiteracyTraining } from '../../__generated__/gql/graphql';

interface CompletionScreenProps {
  training: LiteracyTraining | null;
  onComplete: () => Promise<void>;
}

const CompletionScreen: FC<CompletionScreenProps> = ({
  training,
  onComplete,
}) => {
  const [isCompleting, setIsCompleting] = useState(false);

  const handleComplete = async () => {
    setIsCompleting(true);
    await onComplete();
    setIsCompleting(false);
  };

  return (
    <div className="flex flex-col items-center gap-4 py-4 w-full h-full relative">
      <Card className="w-full max-w-md">
        <CardContent className="flex flex-col items-center text-center pt-6">
          <CheckCircle className="w-12 h-12 text-green-500 mb-4" />

          <h3 className="text-2xl font-bold mb-4">Congratulations!</h3>

          <p className="mb-2 text-base text-muted-foreground">
            You have successfully completed
          </p>

          <p className="text-xl font-semibold mb-6">{training?.title}</p>
          <Button onClick={handleComplete} disabled={isCompleting}>
            {isCompleting ? 'Completing...' : 'Complete Training'}
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};
export default CompletionScreen;
