import { Grid, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import { enabledFeaturesAtom } from '../EnabledFeatures';
import FrameworkManagement from './FrameworkManagement';
import UserManagement from './UserManagement';
import FeatureToggleManagement from './FeatureToggleManagement';

export const OrganizationSettingsPage = () => {
  const enabledFeatures = useRecoilValue(enabledFeaturesAtom);

  return (
    <>
      <Typography variant="h4" marginBottom={3}>
        Organization Settings
      </Typography>
      <Grid container spacing={2} sx={{ width: '100%' }} pb={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserManagement />
            </Grid>
            {enabledFeatures.showFrameworkManagement && (
              <Grid item xs={12}>
                <FrameworkManagement />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <FeatureToggleManagement />
        </Grid>
      </Grid>
      <ToastContainer />
    </>
  );
};
