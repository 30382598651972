import { Stack, Box } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditableTypography from './EditableTypography';
import { X, Plus } from 'lucide-react';
import { Button } from '../components/ui/button';

interface StackEditorProps {
  stacks: string[];
  isEditable: boolean;
  setStacks: (stacks: string[]) => void;
}

const StackEditor: React.FC<StackEditorProps> = ({
  stacks,
  isEditable,
  setStacks,
}) => {
  const handleAddStack = () => {
    const newStack = 'New Element';
    setStacks([...stacks, newStack]);
  };

  const handleDeleteStack = (index: number) => {
    const updatedStacks = [...stacks];
    updatedStacks.splice(index, 1);
    setStacks(updatedStacks);
  };

  const handleEditStack = (index: number, newValue: string) => {
    const updatedStacks = [...stacks];
    updatedStacks[index] = newValue;
    setStacks(updatedStacks);
  };
  return (
    <div className="flex flex-col gap-2">
      <Stack spacing={1} direction={'column'}>
        {stacks.map((stack, index) => (
          <Box
            key={index}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={2}
            minHeight={'36px'}
          >
            <NavigateNextIcon />
            <EditableTypography
              onChange={e => handleEditStack(index, e as string)}
              isEditable={isEditable}
            >
              {stack}
            </EditableTypography>
            <Box
              visibility={isEditable ? 'visible' : 'hidden'}
              minWidth={'48px'}
            >
              <Button
                onClick={() => handleDeleteStack(index)}
                aria-label="delete"
                variant={'ghost'}
              >
                <X />
              </Button>
            </Box>
          </Box>
        ))}
      </Stack>
      {isEditable && (
        <>
          <Button
            variant={'outline'}
            onClick={() => handleAddStack()}
            aria-label="add"
          >
            <Plus />
          </Button>
        </>
      )}
    </div>
  );
};

export default StackEditor;
