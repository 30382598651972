import { Controller, useFormContext } from 'react-hook-form';
import {
  AllowedDataTypeEnum,
  AllowedTaskTypeEnum,
  DataTypeEnum,
  OrganizationDepartmentsQuery,
  RiskClass,
  UsersBasicInfoQuery,
  ItAsset,
} from '../../__generated__/gql/graphql';
import { DateTime } from 'luxon';
import {
  getRiskClassCategoryText,
  getRiskClassRoleText,
} from '../../risk_classification/QuestionnaireResult';
import { EditIcon, PlusIcon } from 'lucide-react';
import { Button } from '../../components/ui/button';

import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { MultiSelect } from '@/components/ui/multi-select';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { formatEnumValue } from '@/utils/formatEnumUtils';
import { FormValues } from './ProjectCreationModal.react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover';
import { Calendar } from '@/components/ui/calendar';
import AddItAssetDialog from '../AddItAssetDialog';
import { useState } from 'react';
import UserFacingModal, { StageDisplayMap } from './UserFacingModal';
import { DefaultDepartments } from '../../constants/departments';

interface ModelSpesificationStageProps {
  usersData: UsersBasicInfoQuery;
  departmentsData: OrganizationDepartmentsQuery;
  riskClass?: RiskClass;
  allItAssets: ItAsset[];
  onNextRiskClassQuestionnaire: () => void;
}

export default function ModelSpesificationStage({
  usersData,
  departmentsData,
  riskClass,
  allItAssets,
  onNextRiskClassQuestionnaire,
}: ModelSpesificationStageProps) {
  const { register, control, getValues, setValue } =
    useFormContext<FormValues>();
  const users = usersData.allUsers || [];
  const [isAddItAssetOpen, setIsAddItAssetOpen] = useState(false);
  const [isUserFacingModalOpen, setIsUserFacingModalOpen] = useState(false);
  const hasUserFacingInfo = Boolean(
    getValues('stage') || getValues('stepsDescription')
  );

  // Combine default + API-provided departments (remove duplicates via Set)
  const allDepartments: string[] = Array.from(
    new Set([
      ...((departmentsData.organization?.departments?.filter(
        Boolean
      ) as string[]) || []),
      ...DefaultDepartments,
    ])
  );
  return (
    <div className="w-full xl:max-w-6xl max-w-5xl mx-auto p-6">
      <div className="space-y-8">
        {/* Project Overview Section */}
        <section className="space-y-4">
          <div className="border-b pb-2">
            <h4>Project Overview</h4>
          </div>

          <div className="space-y-4">
            <div>
              <Label htmlFor="title">Title *</Label>
              <Input
                id="title"
                placeholder="Title of the project"
                className="mt-1.5"
                required
                {...register('title')}
              />
            </div>

            <div>
              <Label htmlFor="description">Description *</Label>
              <Textarea
                id="description"
                placeholder="Provide a detailed description of the project"
                rows={4}
                className="mt-1.5"
                required
                {...register('description')}
              />
            </div>
          </div>
        </section>

        {/* Project Management Section */}
        <section className="space-y-4">
          <div className="border-b pb-2">
            <h4>Project Management</h4>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <Label htmlFor="responsiblePerson">Responsible Person *</Label>
              <Controller
                control={control}
                name="responsiblePerson"
                render={({ field: { value, onChange } }) => (
                  <Select value={value} onValueChange={onChange}>
                    <SelectTrigger className="mt-1.5">
                      <SelectValue placeholder="Select a person" />
                    </SelectTrigger>
                    <SelectContent>
                      {users.map(
                        user =>
                          user && (
                            <SelectItem key={user.id} value={user.id}>
                              {user.name || user.email}
                            </SelectItem>
                          )
                      )}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>

            <div>
              <Label htmlFor="department">Department *</Label>
              <Controller
                control={control}
                name="department"
                rules={{ required: 'Department is required' }}
                render={({ field: { value, onChange }, fieldState }) => (
                  <>
                    <Select value={value} onValueChange={onChange}>
                      <SelectTrigger className="mt-1.5">
                        <SelectValue placeholder="Select a department">
                          {value || ''}
                        </SelectValue>
                      </SelectTrigger>
                      <SelectContent>
                        {allDepartments.map(dept => (
                          <SelectItem key={dept} value={dept}>
                            {dept}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {fieldState.error && (
                      <p className="mt-1 text-sm text-red-500">
                        {fieldState.error.message}
                      </p>
                    )}
                  </>
                )}
              />
            </div>

            <div>
              <Controller
                name="operationEntryDate"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className="flex flex-col space-y-2">
                    <Label>Entry Date</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant="outline"
                          className="w-full justify-start text-left font-normal"
                        >
                          {value
                            ? DateTime.fromJSDate(value).toFormat('yyyy-MM-dd')
                            : 'Pick a date'}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent align="start" className="w-auto p-0">
                        <Calendar
                          mode="single"
                          selected={value ?? undefined}
                          onSelect={date => onChange(date)}
                        />
                      </PopoverContent>
                    </Popover>
                  </div>
                )}
              />
            </div>
          </div>
        </section>

        {/* Technical Configuration Section */}
        <section className="space-y-4">
          <div className="border-b pb-2">
            <h4>Technical Configuration</h4>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {!getValues('isPurchased') && (
              <>
                <div>
                  <Label>Allowed Data Types</Label>
                  <Controller
                    control={control}
                    name="allowedDataTypes"
                    render={({ field }) => (
                      <MultiSelect
                        className="mt-1.5"
                        options={Object.values(AllowedDataTypeEnum).map(
                          value => ({
                            value,
                            label: formatEnumValue(value),
                          })
                        )}
                        selected={(field.value || []).map(value => ({
                          value,
                          label: formatEnumValue(value),
                        }))}
                        onChange={selected =>
                          field.onChange(
                            selected.map(s => s.value as AllowedDataTypeEnum)
                          )
                        }
                        placeholder="Select data types..."
                      />
                    )}
                  />
                </div>

                <div>
                  <Label>Allowed Task Types</Label>
                  <Controller
                    control={control}
                    name="allowedTaskTypes"
                    render={({ field }) => (
                      <MultiSelect
                        className="mt-1.5"
                        options={Object.values(AllowedTaskTypeEnum).map(
                          value => ({
                            value,
                            label: formatEnumValue(value),
                          })
                        )}
                        selected={(field.value || []).map(value => ({
                          value,
                          label: formatEnumValue(value),
                        }))}
                        onChange={selected =>
                          field.onChange(
                            selected.map(s => s.value as AllowedTaskTypeEnum)
                          )
                        }
                        placeholder="Select task types..."
                      />
                    )}
                  />
                </div>
              </>
            )}

            <div>
              <Label>IT Asset</Label>
              <Controller
                name="itAsset"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    value={value}
                    onValueChange={selectedValue => {
                      if (selectedValue === 'add') {
                        setIsAddItAssetOpen(true);
                      } else {
                        onChange(selectedValue);
                      }
                    }}
                  >
                    <SelectTrigger className="mt-1.5">
                      <SelectValue placeholder="Select an IT asset" />
                    </SelectTrigger>
                    <SelectContent>
                      {allItAssets.map(asset => (
                        <SelectItem key={asset.id} value={asset.id ?? ''}>
                          {asset.name}
                        </SelectItem>
                      ))}
                      <SelectItem value="add">+ Add New Asset</SelectItem>
                    </SelectContent>
                  </Select>
                )}
              />
            </div>

            <div>
              <Label>Data Sensitivity Level</Label>
              <Controller
                name="dataType"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select value={value} onValueChange={onChange}>
                    <SelectTrigger className="mt-1.5">
                      <SelectValue placeholder="Select a data sensitivity level" />
                    </SelectTrigger>
                    <SelectContent>
                      {Object.values(DataTypeEnum).map(type => (
                        <SelectItem key={type} value={type}>
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                )}
              />
            </div>
          </div>
        </section>

        {/* Usage Information Section */}
        <section className="space-y-4">
          <div className="border-b pb-2">
            <h4>Usage Information</h4>
          </div>

          <div className="space-y-4">
            <div>
              <Label htmlFor="usagePolicy">Usage Policy</Label>
              <Textarea
                id="usagePolicy"
                rows={3}
                placeholder="Define the usage policy for this project"
                className="mt-1.5"
                {...register('usagePolicy')}
              />
            </div>

            <div>
              <Label htmlFor="users">Users</Label>
              <Textarea
                id="users"
                placeholder="List the intended users here"
                rows={2}
                className="mt-1.5"
                {...register('users')}
              />
            </div>

            <div className="flex flex-wrap gap-4 pt-4">
              {!hasUserFacingInfo && (
                <Button
                  variant="outline"
                  onClick={() => setIsUserFacingModalOpen(true)}
                >
                  <PlusIcon className="mr-2 h-4 w-4" />
                  Add Additional Information
                </Button>
              )}

              {hasUserFacingInfo && (
                <div
                  className="rounded-md border w-full border-yellow-400 p-4 cursor-pointer"
                  onClick={() => setIsUserFacingModalOpen(true)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      setIsUserFacingModalOpen(true);
                    }
                  }}
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <p className="text-sm font-medium mb-2">
                        User Facing Information
                        {getValues('stage') &&
                          StageDisplayMap[
                            getValues('stage') as keyof typeof StageDisplayMap
                          ] &&
                          ` (Stage: ${
                            StageDisplayMap[
                              getValues('stage') as keyof typeof StageDisplayMap
                            ]
                          })`}
                      </p>
                      {getValues('stepsDescription') && (
                        <p className="text-sm text-gray-600">
                          {getValues('stepsDescription')}
                        </p>
                      )}
                    </div>
                    <EditIcon className="text-yellow-400 h-4 w-4" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* Risk Assessment Section */}
        <section className="space-y-4">
          <div className="border-b pb-2">
            <h4>Risk Assessment</h4>
          </div>
          <div className="flex flex-wrap gap-4">
            {!riskClass && (
              <Button variant="outline" onClick={onNextRiskClassQuestionnaire}>
                <PlusIcon className="mr-2 h-4 w-4" />
                Add Risk Class
              </Button>
            )}
            {riskClass && (
              <div className="rounded-md border border-yellow-400  p-4 w-full">
                <p className="text-sm">
                  <strong>Risk Class Assessment: </strong>
                  {riskClass.riskClassRole === 'OUT_OF_SCOPE'
                    ? 'Out of Scope'
                    : `${getRiskClassRoleText(
                        riskClass.riskClassRole
                      )} with ${getRiskClassCategoryText(
                        riskClass.riskClassCategory
                      )} Risk`}
                </p>
              </div>
            )}
          </div>
        </section>
      </div>

      <AddItAssetDialog
        open={isAddItAssetOpen}
        onClose={() => setIsAddItAssetOpen(false)}
        onAdd={newAsset => {
          if (newAsset.id) {
            setValue('itAsset', newAsset.id);
            setIsAddItAssetOpen(false);
          }
        }}
      />
      <UserFacingModal
        open={isUserFacingModalOpen}
        onClose={() => setIsUserFacingModalOpen(false)}
      />
    </div>
  );
}
