import { TextField, Typography, Checkbox, Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { AiDefinitonEnum } from '../__generated__/gql/graphql';

export default function AIPolicyStage() {
  const { register, watch, setValue } = useFormContext();

  const aiManagementSystem = watch('aiManagementSystem');

  const definitionOfAi = watch('definitionOfAi');

  const handleCheckboxChange = (type: string) => {
    if (type === 'yes') {
      setValue('aiManagementSystem.available', true);
      setValue('aiManagementSystem.internalProcess', '');
    } else if (type === 'no') {
      setValue('aiManagementSystem.available', false);
      setValue('aiManagementSystem.internalProcess', '');
    } else if (type === 'custom') {
      setValue('aiManagementSystem.available', null);
    }
  };

  const handleAIDefinitionChange = (type: string) => {
    if (type === 'OECD') {
      setValue('definitionOfAi.predefinedDefinition', AiDefinitonEnum.Oecd);
      setValue('definitionOfAi.customDefinition', '');
    } else if (type === 'NIST') {
      setValue('definitionOfAi.predefinedDefinition', AiDefinitonEnum.Nist);
      setValue('definitionOfAi.customDefinition', '');
    } else if (type === 'custom') {
      setValue('definitionOfAi.predefinedDefinition', null);
    }
  };

  const switchGroupLabels = [
    {
      name: 'aiUseCases.developFinetune',
      text: 'Developing and/or fine-tuning own AI systems',
    },
    {
      name: 'aiUseCases.selling',
      text: 'Selling or Offering AI systems',
    },
    {
      name: 'aiUseCases.thirdParty',
      text: 'Using a third party AI system',
    },
    {
      name: 'aiUseCases.adaptingThirdParty',
      text: 'Adapting a third party AI system',
    },
  ];

  const purposeOfAIPolicyLabels = [
    {
      name: 'purposeOfAiPolicy.outlineUseCases',
      text: 'Outline acceptable use cases of AI in organisation ',
    },
    {
      name: 'purposeOfAiPolicy.governingDevelopment',
      text: 'Governing responsible development of AI ',
    },
    {
      name: 'purposeOfAiPolicy.promoteAiLiteracy',
      text: 'Promote AI literacy in organisation ',
    },
    {
      name: 'purposeOfAiPolicy.alignAiWithOrganisation',
      text: 'Aligning AI usage/principles with organisational values and reputation',
    },
    {
      name: 'purposeOfAiPolicy.safeAiUsage',
      text: 'Fostering responsible and safe AI usage',
    },
  ];

  return (
    <>
      <Typography variant="h6" marginBottom={'0.5rem'} fontWeight={600}>
        AI Strategy
      </Typography>
      <TextField
        id="outlined-basic"
        label="What is your AI strategy?"
        variant="outlined"
        multiline
        rows={5}
        {...register('aiStrategy')}
        sx={{ width: '100%', marginBottom: '1rem' }}
      />
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        Definition of AI
      </Typography>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleAIDefinitionChange('OECD')}
          checked={definitionOfAi.predefinedDefinition === AiDefinitonEnum.Oecd}
        />
        <Typography sx={{ fontSize: '1rem' }}>
          OECD's definition of AI
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleAIDefinitionChange('NIST')}
          checked={definitionOfAi.predefinedDefinition === AiDefinitonEnum.Nist}
        />
        <Typography sx={{ fontSize: '1rem' }}>
          NIST'S definition of AI
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleAIDefinitionChange('custom')}
          checked={definitionOfAi.predefinedDefinition === null}
        />
        <Typography sx={{ fontSize: '1rem' }}>Custom Definiton</Typography>
      </Box>
      {definitionOfAi.predefinedDefinition === null && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <TextField
            {...register('definitionOfAi.customDefinition')}
            label="Describe the custom definition..."
            fullWidth
            onChange={e =>
              setValue('definitionOfAi.customDefinition', e.target.value)
            }
          />
        </Box>
      )}
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        Do you have an AI management system in place (to ensure proper
        governance of the AI systems used and developed)?
      </Typography>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleCheckboxChange('yes')}
          checked={aiManagementSystem.available === true}
        />
        <Typography sx={{ fontSize: '1rem' }}>
          Yes, the dedicated software trail
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleCheckboxChange('no')}
          checked={aiManagementSystem.available === false}
        />
        <Typography sx={{ fontSize: '1rem' }}>No</Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
        <Checkbox
          onChange={() => handleCheckboxChange('custom')}
          checked={aiManagementSystem.available === null}
        />
        <Typography sx={{ fontSize: '1rem' }}>Yes, internal process</Typography>
      </Box>
      {aiManagementSystem.available === null && (
        <Box sx={{ mt: 2 }}>
          <TextField
            {...register('aiManagementSystem.internalProcess')}
            label="Describe the internal process"
            fullWidth
            onChange={e =>
              setValue('aiManagementSystem.internalProcess', e.target.value)
            }
          />
        </Box>
      )}
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        What is the purpose and goal of this AI policy?
      </Typography>
      {purposeOfAIPolicyLabels.map((item, index) => (
        <Box
          sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          key={index + '_' + item.name}
        >
          <Controller
            name={item.name}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                onChange={e => onChange(e.target.checked)}
                checked={value}
              />
            )}
          />
          <Typography sx={{ fontSize: '1rem' }}>{item.text}</Typography>
        </Box>
      ))}
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        AI Use Cases
      </Typography>
      {switchGroupLabels.map((item, index) => (
        <Box
          sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          key={index + '_' + item.name}
        >
          <Controller
            name={item.name}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                onChange={e => onChange(e.target.checked)}
                checked={value}
              />
            )}
          />
          <Typography sx={{ fontSize: '1rem' }}>{item.text}</Typography>
        </Box>
      ))}
      <Typography
        variant="h6"
        marginBottom={'1rem'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        Violation of Policy
      </Typography>
      <TextField
        id="outlined-basic"
        label="Who is responsible for handling violation of this AI Policy and related actions? (e.g. HR, supervisor)"
        variant="outlined"
        {...register('violationResponsible')}
        sx={{ width: '100%', marginBottom: '1rem' }}
      />
    </>
  );
}
