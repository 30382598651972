import { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ItAsset } from '../__generated__/gql/graphql';
import { GET_IT_ASSETS } from './ProjectView';
import { Button } from '../components/ui/button';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

export const CREATE_IT_ASSET = gql`
  mutation CreateItAsset($name: String!) {
    createItAsset(name: $name) {
      itAsset {
        id
        name
        created
        lastModified
      }
    }
  }
`;

interface AddItAssetDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (asset: ItAsset) => void;
}

export default function AddItAssetDialog({
  open,
  onClose,
  onAdd,
}: AddItAssetDialogProps) {
  const [name, setName] = useState('');

  const [createItAsset] = useMutation(CREATE_IT_ASSET, {
    update(cache, { data }) {
      if (!data?.createItAsset?.itAsset) return;
      const existingData = cache.readQuery<{ allItAssets: ItAsset[] }>({
        query: GET_IT_ASSETS,
      });
      cache.writeQuery({
        query: GET_IT_ASSETS,
        data: {
          allItAssets: [
            ...(existingData?.allItAssets ?? []),
            data.createItAsset.itAsset,
          ],
        },
      });
    },
    onCompleted: data => {
      if (data?.createItAsset?.itAsset) {
        onAdd(data.createItAsset.itAsset);
        setName('');
        onClose();
        toast.success('IT Asset created successfully');
      }
    },
    onError: error => {
      if (error.message.includes('duplicate key error')) {
        toast.error('An IT Asset with this name already exists');
      } else {
        toast.error('Failed to create IT Asset');
      }
    },
  });

  const handleSubmit = async () => {
    try {
      await createItAsset({
        variables: { name: name.trim() },
      });
    } catch (error) {
      console.error('Error creating IT Asset:', error);
    }
  };

  function handleClose() {
    setName('');
    onClose();
  }

  return (
    <Dialog open={open} onOpenChange={isOpen => !isOpen && handleClose()}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle>Add New IT Asset</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-1">
            <Label htmlFor="asset-name">Asset Name</Label>
            <Input
              id="asset-name"
              value={name}
              onChange={e => setName(e.target.value)}
              required
              autoFocus
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} disabled={!name.trim()}>
            Add
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
