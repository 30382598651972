import { Controller, useFormContext } from 'react-hook-form';
import { Stage } from '../../__generated__/gql/graphql';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from '@/components/ui/sheet';

interface UserFacingModalProps {
  open: boolean;
  onClose: (hasInfo?: boolean) => void;
}

export default function UserFacingModal({
  open,
  onClose,
}: UserFacingModalProps) {
  const { register, control, getValues } = useFormContext();

  const handleClose = () => {
    const { stage, stepsDescription } = getValues();
    const hasInfo = Boolean(stage || stepsDescription);
    onClose(hasInfo);
  };

  return (
    <Sheet open={open} onOpenChange={handleClose}>
      <SheetContent className="w-[400px] sm:w-[540px]">
        <SheetHeader>
          <SheetTitle>User Facing Model Card</SheetTitle>
        </SheetHeader>
        <div className="space-y-6 py-6">
          <div className="space-y-2">
            <Label htmlFor="title">Title</Label>
            <Input
              id="title"
              placeholder="Title of the project"
              {...register('title')}
            />
          </div>

          <div className="space-y-2">
            <Label>Stage</Label>
            <Controller
              name="stage"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select value={value} onValueChange={onChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select a stage" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.values(Stage).map(stage => (
                      <SelectItem key={stage} value={stage}>
                        {StageDisplayMap[stage]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="stepsDescription">How to get started</Label>
            <Textarea
              id="stepsDescription"
              placeholder="What are the steps to get access to the tool: eg. credentials, training, etc."
              rows={4}
              {...register('stepsDescription')}
            />
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
}

export const StageDisplayMap = {
  DEVELOP: 'Develop',
  DEPLOY: 'Deploy',
  OPERATE: 'Operate',
  PLAN: 'Plan',
  RETIRE: 'Retire',
};
