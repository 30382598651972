import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { DateTime } from 'luxon';
import { Interval, User } from '../../../__generated__/gql/graphql';
import UserTag from '../../../components/UserTag';
import { Calendar } from '@/components/ui/calendar';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { CalendarIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface BasicInfoFormData {
  title: string;
  description: string;
  responsiblePersonId: string;
  interval: Interval;
  isRequired: boolean;
  dueDate?: DateTime;
}

interface BasicInfoStepProps {
  allUsers: User[];
}

const BasicInfoStep: FC<BasicInfoStepProps> = ({ allUsers }) => {
  const { control } = useFormContext<BasicInfoFormData>();

  return (
    <div className="space-y-6 p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Title Field */}
        <div className="col-span-full">
          <FormField
            control={control}
            name="title"
            rules={{
              required: 'Title is required',
              minLength: {
                value: 3,
                message: 'Title must be at least 3 characters long',
              },
              maxLength: {
                value: 100,
                message: 'Title must be less than 100 characters',
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Title</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter training title"
                    {...field}
                    className="bg-background"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Description Field */}
        <div className="col-span-full">
          <FormField
            control={control}
            name="description"
            rules={{
              required: 'Description is required',
              minLength: {
                value: 10,
                message: 'Description must be at least 10 characters long',
              },
              maxLength: {
                value: 1000,
                message: 'Description must be less than 1000 characters',
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Description</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Enter training description"
                    rows={4}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Responsible Person Field */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="responsiblePersonId"
            rules={{ required: 'Responsible person is required' }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Responsible Person</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select responsible person" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {allUsers.map(user => (
                      <SelectItem key={user.id} value={user.id}>
                        <UserTag user={user} variant="text" size="medium" />
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  Select the person responsible for managing this training
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Due Date Field */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="dueDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Due Date</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        className={cn(
                          'w-full justify-start text-left font-normal',
                          !field.value && 'text-muted-foreground'
                        )}
                      >
                        {field.value instanceof DateTime
                          ? field.value.toFormat('yyyy-MM-dd')
                          : 'Pick a date'}
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={
                        field.value instanceof DateTime
                          ? field.value.toJSDate()
                          : undefined
                      }
                      onSelect={date => {
                        field.onChange(
                          date ? DateTime.fromJSDate(date) : undefined
                        );
                      }}
                      disabled={date =>
                        date < DateTime.now().startOf('day').toJSDate()
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Interval Field */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="interval"
            defaultValue={Interval.Yearly}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Interval</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select interval" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.values(Interval).map(intervalValue => (
                      <SelectItem key={intervalValue} value={intervalValue}>
                        {intervalValue.charAt(0).toUpperCase() +
                          intervalValue.slice(1).toLowerCase()}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormDescription>
                  How often should this training be repeated?
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/* Required Training Switch */}
        <div className="col-span-full md:col-span-1">
          <FormField
            control={control}
            name="isRequired"
            render={({ field }) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="text-base">Required Training</FormLabel>
                  <FormDescription>
                    Toggle if this training is mandatory for all users
                  </FormDescription>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfoStep;
