import {
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  Checkbox,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { colorThemes } from '../theme';
import { RiskProfileEnum } from '../__generated__/gql/graphql';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function ScopeStage() {
  const { register, control } = useFormContext();

  const {
    fields: fieldsRisks,
    append: appendRisk,
    remove: removeRisk,
  } = useFieldArray({
    control,
    name: 'risks',
  });
  const addFieldRisk = () => {
    appendRisk('');
  };

  const {
    fields: fieldsOpportunities,
    append: appendOpportunity,
    remove: removeOpportunity,
  } = useFieldArray({
    control,
    name: 'opportunities',
  });
  const addFieldOpportunity = () => {
    appendOpportunity('');
  };

  const {
    fields: fieldsProhibitedSystems,
    append: appendProhibitedSystem,
    remove: removeProhibitedSystem,
  } = useFieldArray({
    control,
    name: 'prohibitedSystems',
  });
  const addProhibitedSystem = () => {
    appendProhibitedSystem({ systemName: '', provider: '', reason: '' });
  };

  const switchGroupLabels = [
    {
      name: 'considerInternalExternal',
      text: 'Did you consider the Internal and External Issues?',
    },
    {
      name: 'considerRequirements',
      text: 'Did you consider the previously defined requirements?',
    },
  ];

  return (
    <>
      <Typography variant="h6" marginBottom={'0.5rem'} fontWeight={600}>
        Scope
      </Typography>
      <TextField
        id="outlined-basic"
        label="What is the scope of the AI Manaegement System?"
        variant="outlined"
        {...register('systemScope')}
        sx={{ width: '100%', marginBottom: '1rem' }}
      />
      <Typography variant="h6" marginBottom={'0.5rem'} fontWeight={600}>
        Resources
      </Typography>
      <TextField
        id="outlined-basic"
        label="What resources are required?"
        variant="outlined"
        {...register('systemResources')}
        sx={{ width: '100%', marginBottom: '1rem' }}
      />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
        }}
        marginBottom={'0.5rem'}
      >
        <Typography variant="h6" fontWeight={600}>
          Risk Profile
        </Typography>
        <Tooltip
          title={
            <Box>
              <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                Risk Profile:
              </Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Conservative (Only low risk)" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Moderate (Open to take risks after careful consideration)" />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Aggressive (Very high risk appetite with close to zero unacceptable risks)" />
                </ListItem>
              </List>
            </Box>
          }
          arrow
        >
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Controller
        name="riskProfile"
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel>
              What level of risk are you comfortable with?
            </InputLabel>
            <Select
              {...field}
              label="What level of risk are you comfortable with?"
              sx={{ width: '100%', marginBottom: '1rem' }}
            >
              {Object.entries(RiskProfileEnum).map(([, val]) => (
                <MenuItem key={val} value={val}>
                  {riskProfileLabels[val]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        rules={{ required: false }}
      />
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        Prohibited Systems
      </Typography>
      <Button
        sx={{
          color: 'white',
          fontWeight: 'bold',
          gap: '4px',
          paddingRight: '1rem',
          backgroundColor: colorThemes.ACTION_BLUE,
        }}
        onClick={addProhibitedSystem}
      >
        <AddIcon />
        Add Prohibited System
      </Button>
      {fieldsProhibitedSystems.map((field, index) => (
        <Grid container spacing={2} key={field.id} marginTop={'5px'}>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={'Name of the system'}
              variant="outlined"
              {...register(`prohibitedSystems.${index}.systemName`)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="outlined-basic"
              label={'Who provides the AI System?'}
              variant="outlined"
              {...register(`prohibitedSystems.${index}.provider`)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-basic"
              label={'Why is this system prohibited?'}
              variant="outlined"
              {...register(`prohibitedSystems.${index}.reason`)}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={1} sm={1}>
            <IconButton
              onClick={() => removeProhibitedSystem(index)}
              sx={{ height: '100%' }}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        Risks
      </Typography>
      <Button
        sx={{
          color: 'white',
          fontWeight: 'bold',
          gap: '4px',
          paddingRight: '1rem',
          backgroundColor: colorThemes.ACTION_BLUE,
        }}
        onClick={addFieldRisk}
      >
        <AddIcon />
        Add Risk
      </Button>
      {fieldsRisks.map((field, index) => (
        <Grid container spacing={2} key={field.id} marginTop={'5px'}>
          <Grid item xs={11}>
            <TextField
              id="outlined-basic"
              label={'Risk' + (index + 1)}
              variant="outlined"
              {...register(`risks.${index}`)}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={1} sm={1}>
            <IconButton
              onClick={() => removeRisk(index)}
              sx={{ height: '100%' }}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        Opportunities
      </Typography>
      <Button
        sx={{
          color: 'white',
          fontWeight: 'bold',
          gap: '4px',
          paddingRight: '1rem',
          backgroundColor: colorThemes.ACTION_BLUE,
        }}
        onClick={addFieldOpportunity}
      >
        <AddIcon />
        Add Opportunity
      </Button>
      {fieldsOpportunities.map((field, index) => (
        <Grid container spacing={2} key={field.id} marginTop={'5px'}>
          <Grid item xs={11}>
            <TextField
              id="outlined-basic"
              label={'Opportunity' + (index + 1)}
              variant="outlined"
              {...register(`opportunities.${index}`)}
              sx={{ width: '100%' }}
            />
          </Grid>

          <Grid item xs={1} sm={1}>
            <IconButton
              onClick={() => removeOpportunity(index)}
              sx={{ height: '100%' }}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Typography
        variant="h6"
        marginBottom={'5px'}
        marginTop={'1.5rem'}
        fontWeight={600}
      >
        Considerations
      </Typography>
      {switchGroupLabels.map((item, index) => (
        <Box
          sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
          key={index + '_' + item.name}
        >
          <Controller
            name={item.name}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                onChange={e => onChange(e.target.checked)}
                checked={value}
              />
            )}
          />
          <Typography sx={{ fontSize: '1rem' }}>{item.text}</Typography>
        </Box>
      ))}
    </>
  );
}

const riskProfileLabels = {
  CONSERVATIVE: 'Conservative and low risk appetite',
  MODERATE:
    'Medium risk appetite with mainly low risk and limited risk use cases, but some experimental high risk ',
  AGGRESSIVE: 'High risk appetite and experimental AI cases',
};
