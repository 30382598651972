import React, { useState } from 'react';
import { List, Collapse } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface FormNavigationSidebarProps {
  categories: Array<{
    categoryName: string;
    sections: Array<{ label: string; infoColor?: string }>;
  }>;
  activeSection: string;
  onNavigate: (categoryIndex: number, sectionIndex: number) => void;
}

export const buildRiskSectionId = (catIdx: number, secIdx: number) => {
  return `${catIdx}-${secIdx}`;
};

export default function FormNavigationSidebar({
  categories,
  activeSection,
  onNavigate,
}: FormNavigationSidebarProps) {
  const [openCategories, setOpenCategories] = useState<{
    [key: string]: boolean;
  }>({});

  const handleToggle = (categoryName: string) => {
    setOpenCategories(prevOpenCategories => ({
      ...prevOpenCategories,
      [categoryName]: !prevOpenCategories[categoryName],
    }));
  };

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 0.5,
        p: 0.5,
      }}
      component="nav"
    >
      {categories.map((category, catIdx) => (
        <React.Fragment key={category.categoryName}>
          <Button
            variant="ghost"
            onClick={() => handleToggle(category.categoryName)}
            className="w-full text-left h-20"
          >
            <div className="flex justify-between items-start w-full">
              <div className="flex-1 pr-2 whitespace-normal break-words">
                {category.categoryName}
              </div>
              {openCategories[category.categoryName] ? (
                <ChevronUp className="flex-shrink-0" />
              ) : (
                <ChevronDown className="flex-shrink-0" />
              )}
            </div>
          </Button>
          <Collapse
            in={openCategories[category.categoryName]}
            timeout="auto"
            unmountOnExit
          >
            {category.sections.map((section, secIdx) => (
              <Button
                variant="ghost"
                key={section.label}
                onClick={() => onNavigate(catIdx, secIdx)}
                className={`w-full text-left pl-10 min-h-18 ${
                  activeSection === buildRiskSectionId(catIdx, secIdx)
                    ? 'bg-dark-blue-200'
                    : ''
                }`}
              >
                <div className="flex justify-between items-start w-full">
                  <div className="flex-1 pr-2 whitespace-normal break-words">
                    {section.label}
                  </div>
                  {section.infoColor && (
                    <CircleIcon
                      className="flex-shrink-0"
                      style={{ color: section.infoColor }}
                    />
                  )}
                </div>
              </Button>
            ))}
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
}
