import { Box, Grid } from '@mui/material';
import { Pencil, Save, Trash2, X } from 'lucide-react';
import EditableTypography from '../../project_view/EditableTypography';
import { Button } from '../../components/ui/button';

interface TrainingDrawerHeaderProps {
  title: string;
  isEditable: boolean;
  onTitleChange: (title: string) => void;
  onEdit: () => void;
  onSave: () => void;
  onDelete: () => void;
}

export function TrainingDrawerHeader({
  title,
  isEditable,
  onTitleChange,
  onEdit,
  onSave,
  onDelete,
}: TrainingDrawerHeaderProps) {
  return (
    <Grid
      sx={{
        width: '100%',
        py: '30px',
        display: 'flex',
        justifyContent: 'start',
      }}
    >
      <Box sx={{ px: '30px', width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'space-between',
            pb: '10px',
          }}
        >
          <Box sx={{ flexGrow: 1, minWidth: 0, mr: 2 }}>
            <EditableTypography
              variant="h4"
              multiline={false}
              isEditable={isEditable}
              onChange={e => onTitleChange(e as string)}
            >
              {title}
            </EditableTypography>
          </Box>
          <Box display="flex" marginLeft="auto" gap={2}>
            <Button
              onClick={onEdit}
              variant={isEditable ? 'outline' : 'default'}
              className="flex items-center gap-2"
            >
              {isEditable ? (
                <X className="h-4 w-4" />
              ) : (
                <Pencil className="h-4 w-4" />
              )}
              {isEditable ? 'Cancel' : 'Edit'}
            </Button>
            {isEditable && (
              <>
                <Button
                  onClick={onSave}
                  variant="outline"
                  className="ml-2 flex items-center gap-2"
                >
                  <Save className="h-4 w-4" />
                  Save
                </Button>
                <Button
                  onClick={onDelete}
                  variant="outline"
                  className="ml-2 flex items-center gap-2"
                >
                  <Trash2 className="h-4 w-4" />
                  Delete
                </Button>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
