import { Box } from '@mui/system';
import { FC, useEffect, useMemo, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Typography } from '@mui/material';
import { gql, useSuspenseQuery } from '@apollo/client';
import {
  GetallUsersforTrainingQuery,
  GetLiteracyTrainingsQuery,
  GetTrailLiteracyTrainingPreviewsQuery,
  GetTrailTrainingAddonAccessQuery,
  Interval,
  LiteracyTraining,
  TrailLiteracyTrainingPreview,
  User,
} from '../__generated__/gql/graphql';
import TrainingCreationModal from './training_creation_modal/TrainingCreationModal';
import TrainingDrawer from './TrainingsDrawer';
import CardGrid from '../components/CardGrid';
import TrainingAdminDetailCard from './TrainingAdminDetailCard';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../components/ui/button';
import { GraduationCap } from 'lucide-react';

export const GET_LITERACY_TRAININGS = gql`
  query GetLiteracyTrainings($targetQueryMutation: String!, $action: String!) {
    checkPermission(
      targetQueryMutation: $targetQueryMutation
      action: $action
    ) {
      hasPermission
    }
    allLiteracyTrainings {
      id
      title
      responsiblePerson {
        id
        name
        email
      }
      description
      isRequired
      interval
      trainingMaterial {
        id
        fileName
      }
      dueDate
      completionLogs {
        id
        user {
          id
          name
          email
        }
        completionDate
      }
      lastModified
      created
    }
  }
`;

const GET_USERS = gql`
  query getallUsersforTraining {
    allUsers {
      id
      email
      name
      initials
    }
  }
`;

const GET_TRAIL_LITERACY_TRAINING_PREVIEWS = gql`
  query GetTrailLiteracyTrainingPreviews {
    trailLiteracyTrainingPreviews {
      id
      previewTitle
      description
      trainingMaterial {
        id
        url
        contentBase64
      }
    }
  }
`;

const GET_TRAIL_TRAINING_ADDON_ACCESS = gql`
  query getTrailTrainingAddonAccess {
    organization {
      addonAccessControl {
        hasLiteracyAccess
      }
    }
  }
`;

const TrainingAdminDashboardPage: FC = () => {
  const navigate = useNavigate();
  const { data: allTrainingData } = useSuspenseQuery<GetLiteracyTrainingsQuery>(
    GET_LITERACY_TRAININGS,
    {
      variables: {
        targetQueryMutation: 'createTraining',
        action: 'mutation',
      },
    }
  );
  const { data: literacyAccess } =
    useSuspenseQuery<GetTrailTrainingAddonAccessQuery>(
      GET_TRAIL_TRAINING_ADDON_ACCESS
    );
  const hasLiteracyAccess =
    literacyAccess?.organization?.addonAccessControl?.hasLiteracyAccess ??
    false;

  useEffect(() => {
    if (!allTrainingData?.checkPermission?.hasPermission) {
      navigate('/literacy');
    }
  }, [allTrainingData, navigate]);

  const filteredTraining = useMemo(
    () => (allTrainingData?.allLiteracyTrainings as LiteracyTraining[]) || [],
    [allTrainingData]
  );
  const { data: allUserData } =
    useSuspenseQuery<GetallUsersforTrainingQuery>(GET_USERS);
  const { data: previewsData } =
    useSuspenseQuery<GetTrailLiteracyTrainingPreviewsQuery>(
      GET_TRAIL_LITERACY_TRAINING_PREVIEWS
    );
  const [selectedTraining, setSelectedTraining] =
    useState<LiteracyTraining | null>(null);
  const [showCreationModal, setShowCreationModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const handleTrainingSelect = (training: LiteracyTraining) => {
    setSelectedTraining(training);
    setShowDrawer(true);
  };
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        width={'100%'}
        alignItems="center"
      >
        <Typography variant="h4" marginBottom={3}>
          Admin Training Dashboard
        </Typography>
        <Link to="/literacy">
          <Button>
            <GraduationCap />
            Trainings View
          </Button>
        </Link>
      </Box>
      {/* <FilterSection /> */}
      <CardGrid
        items={filteredTraining}
        title={
          filteredTraining.length === 0
            ? 'No Trainings created yet'
            : filteredTraining.length > 1
            ? filteredTraining.length + ' Trainings created'
            : '1 Training created'
        }
        renderCard={training => (
          <TrainingAdminDetailCard
            key={training.id}
            id={training.id}
            onClick={() => handleTrainingSelect(training)}
            title={training.title}
            isSelected={selectedTraining?.id === training.id}
            user={training?.responsiblePerson ?? {}}
            interval={training?.interval ?? Interval.Yearly}
            updatedAt={training?.lastModified ?? 'not update yet'}
            required={training?.isRequired ?? false}
          />
        )}
        onAddNew={() => setShowCreationModal(true)}
        addNewCardProps={{ width: 400, height: 200 }}
      />

      {showCreationModal && (
        <TrainingCreationModal
          isOpen={showCreationModal}
          onClose={() => setShowCreationModal(false)}
          allUser={(allUserData.allUsers as User[]) || []}
          availableTrainingMaterials={
            previewsData?.trailLiteracyTrainingPreviews?.filter(
              (preview): preview is TrailLiteracyTrainingPreview =>
                preview !== null
            ) || []
          }
          hasLiteracyAccess={hasLiteracyAccess}
        />
      )}

      {selectedTraining && (
        <TrainingDrawer
          training={selectedTraining}
          isOpen={showDrawer}
          onClose={() => {
            setShowDrawer(false);
            setSelectedTraining(null);
          }}
          allUsers={(allUserData?.allUsers as User[]) || []}
        />
      )}

      <ToastContainer />
    </>
  );
};

export default TrainingAdminDashboardPage;
