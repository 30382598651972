import React, { FC, useState } from 'react';
import { Card, CardContent, CardHeader, Collapse } from '@mui/material';
import { Minimize2, ChevronDown } from 'lucide-react';

interface ExpandableCardProps {
  title: string;
  titleIcon?: React.ReactNode;
  children: React.ReactNode;
  initiallyExpanded?: boolean;
  sx?: object;
}

const ExpandableCard: FC<ExpandableCardProps> = ({
  title,
  titleIcon,
  children,
  initiallyExpanded = false,
  sx,
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={sx}>
      <CardHeader
        title={title}
        onClick={handleExpandClick}
        action={
          <div className="flex gap-2 h-full items-baseline cursor-pointer">
            {titleIcon}
            {expanded ? <Minimize2 /> : <ChevronDown />}
          </div>
        }
        sx={{ cursor: 'pointer' }}
      />
      <Collapse in={expanded} timeout="auto">
        <CardContent sx={{ paddingTop: 0 }}>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

export default ExpandableCard;
