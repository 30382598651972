import {
  Box,
  Card,
  CardContent,
  Tab,
  Tabs,
  Badge,
  Typography,
  Button,
  ListItemText,
  Collapse,
  IconButton,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { colorThemes } from '../theme';
import { Done, DoneAll, OpenInNew } from '@mui/icons-material';
import { Notification } from '../__generated__/gql/graphql';

const MARK_NOTIFICATIONS_AS_READ_MUTATION = gql`
  mutation MarkNotificationsAsRead($userId: ID!, $notificationId: ID) {
    markNotificationsAsRead(userId: $userId, notificationId: $notificationId) {
      user {
        notification {
          id
          name
          description
          isRead
          actionUrl
        }
      }
    }
  }
`;

export default function UserNotifications({
  notifications,
  userId,
}: {
  notifications: Notification[];
  userId: string;
}) {
  const [tabIndex, setTabIndex] = useState(0);

  const [markNotificationsAsRead] = useMutation(
    MARK_NOTIFICATIONS_AS_READ_MUTATION
  );

  const handleMarkAllAsRead = () => {
    markNotificationsAsRead({
      variables: { userId },
      optimisticResponse: {
        __typename: 'Mutation',
        markNotificationsAsRead: {
          __typename: 'MarkNotificationsAsReadPayload',
          user: {
            __typename: 'User',
            notification: notifications.map(notification => ({
              ...notification,
              isRead: true,
              __typename: 'Notification',
            })),
          },
        },
      },
    });
  };

  const handleMarkAsRead = (notificationId: string) => {
    markNotificationsAsRead({
      variables: { userId, notificationId },
      optimisticResponse: {
        __typename: 'Mutation',
        markNotificationsAsRead: {
          __typename: 'MarkNotificationsAsReadPayload',
          user: {
            __typename: 'User',
            notification: notifications.map(notification => {
              if (notification.id === notificationId) {
                return {
                  ...notification,
                  isRead: true,
                  __typename: 'Notification',
                };
              }
              return {
                ...notification,
                __typename: 'Notification',
              };
            }),
          },
        },
      },
    });
  };

  const unreadNotifications = notifications.filter(n => !n.isRead);
  const readNotifications = notifications.filter(n => n.isRead);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Card className="h-full">
      <CardContent
        sx={{
          padding: '16px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: colorThemes.DARK_BLUE_600,
        }}
      >
        {/* Header with Tabs */}
        <Box
          sx={{
            borderBottom: 1,
            borderColor: colorThemes.DARK_BLUE_400,
            marginBottom: '16px',
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
          >
            <Tab
              label={
                <Badge
                  badgeContent={unreadNotifications.length}
                  color="primary"
                  sx={{ pr: '5px' }}
                >
                  Open
                </Badge>
              }
              sx={{ color: colorThemes.YELLOW_200 }}
            />
            <Tab
              label={`Done (${readNotifications.length})`}
              sx={{ color: colorThemes.YELLOW_200 }}
            />
          </Tabs>
        </Box>

        {/* Notification Lists */}
        <Box
          sx={{
            flex: 1,
            overflowY: 'auto',
            minHeight: 0,
            paddingRight: '8px',
            marginRight: '-8px',
          }}
        >
          {' '}
          {tabIndex === 0 ? (
            unreadNotifications.length > 0 ? (
              unreadNotifications.map(notification => (
                <NotificationCard
                  key={notification.id}
                  notification={notification}
                  onMarkAsRead={handleMarkAsRead}
                />
              ))
            ) : (
              <Typography variant="body1">No open notifications</Typography>
            )
          ) : readNotifications.length > 0 ? (
            readNotifications.map(notification => (
              <NotificationCard
                key={notification.id}
                notification={notification}
                onMarkAsRead={handleMarkAsRead}
              />
            ))
          ) : (
            <Typography variant="body1">No read notifications</Typography>
          )}
        </Box>

        {/* Mark All as Read Button */}
        <Box
          sx={{
            textAlign: 'right',
            background: colorThemes.DARK_BLUE_600,
            padding: '16px 16px 0px 16px',
            borderTop: `1px solid ${colorThemes.DARK_BLUE_400}`,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleMarkAllAsRead}
            sx={{
              backgroundColor: colorThemes.YELLOW_400,
              color: colorThemes.DARK_BLUE_600,
              ':hover': { backgroundColor: colorThemes.YELLOW_200 },
            }}
            disabled={unreadNotifications.length === 0 || tabIndex === 1}
            startIcon={<DoneAll sx={{ color: 'black' }} />}
          >
            Mark All as Read
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

const NotificationCard = ({
  notification,
  onMarkAsRead,
}: {
  notification: Notification;
  onMarkAsRead: (id: string) => void;
}) => {
  const [visible, setVisible] = useState(true);

  const handleMarkAsReadClick = () => {
    if (!notification.isRead) {
      setVisible(false);
      setTimeout(() => {
        onMarkAsRead(notification.id);
      }, 300);
    }
  };

  return (
    <Collapse in={visible} timeout={300}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
          backgroundColor: notification.isRead
            ? colorThemes.DARK_BLUE_400
            : colorThemes.DARK_BLUE_200,
          borderRadius: '12px',
          marginBottom: '12px',
          borderLeft: `6px solid ${
            notification.isRead
              ? colorThemes.DARK_BLUE_400
              : colorThemes.YELLOW_200
          }`,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          transition: 'all 0.3s ease',
          gap: '16px',
        }}
      >
        {/* Notification Content */}
        <ListItemText
          primaryTypographyProps={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: notification.isRead ? '#B0BEC5' : '#FFFFFF',
          }}
          secondaryTypographyProps={{
            fontSize: '14px',
            color: '#90A4AE',
            mt: '4px',
          }}
          primary={notification.name}
          secondary={notification.description}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {/* Mark as Read button */}
          {!notification.isRead && (
            <IconButton
              size="small"
              sx={{
                borderColor: colorThemes.YELLOW_200,
                color: colorThemes.YELLOW_200,
                ':hover': {
                  borderColor: colorThemes.YELLOW_400,
                  color: colorThemes.YELLOW_400,
                },
                transition: 'color 0.2s, border-color 0.2s',
              }}
              onClick={e => {
                e.stopPropagation();
                handleMarkAsReadClick();
              }}
            >
              <Done />
            </IconButton>
          )}

          {/* View button */}
          <Button
            size="small"
            component={Link}
            variant="outlined"
            to={notification.actionUrl || '/'}
            sx={{
              color: colorThemes.YELLOW_200,
              borderColor: colorThemes.YELLOW_200,
              ':hover': {
                color: colorThemes.YELLOW_400,
                borderColor: colorThemes.YELLOW_400,
              },
              transition: 'color 0.2s, border-color 0.2s',
            }}
            endIcon={
              <OpenInNew
                sx={{
                  color: colorThemes.YELLOW_200,
                  ':hover': {
                    color: colorThemes.YELLOW_400,
                  },
                }}
              />
            }
            onClick={e => e.stopPropagation()}
          >
            View
          </Button>
        </Box>
      </Box>
    </Collapse>
  );
};
