import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import ExternalContextStage from './ExternalContextStage';
import InternalContextStage from './InternalContextStage';
import AIObjectivesStage from './AIObjectivesStage';
import ScopeStage from './ScopeStage';
import { scrollbarSx } from '../components/StyledScrollable';
import { gql, useMutation, useSuspenseQuery } from '@apollo/client';
import { OrganizationQuery } from '../__generated__/gql/graphql';
import { ToastContainer, toast } from 'react-toastify';
import { colorThemes } from '../theme';
import { TabContext, TabList } from '@mui/lab';
import AIPolicyStage from './AIPolicyStage';

const GET_QUESTIONNAIRE = gql(`
query Organization {
  organization {
    id
    profileQuestionnaire {
      countries
      associatedPolicies
      valueNorms {
        organizationValue
        description
      }
      externalContext
      externalParties {
        name
        requirements
      }
      internalContext
      contractualObligations
      internalParties {
        name
        requirements
      }
      isClimateRelevant
      objectives {
        objectiveName
        description
        measure
      }
      areObjectsMonitored
      areObjectsConsistent
      areObjectsCommunicated
      areObjectsApplicable
      principles {
        principleName
        description
        measure
      }
      systemScope
      systemResources
      risks
      riskProfile
      prohibitedSystems {
        systemName
        provider
        reason
      }
      opportunities
      considerInternalExternal
      considerRequirements
      reviewPeriod
      aiStrategy
      aiUseCases {
        adaptingThirdParty
        thirdParty
        selling
        developFinetune
      }
      aiManagementSystem {
        available
        internalProcess
      }
      violationResponsible
      definitionOfAi {
        customDefinition
        predefinedDefinition
      }
      purposeOfAiPolicy {
        alignAiWithOrganisation
        governingDevelopment
        outlineUseCases
        promoteAiLiteracy
        safeAiUsage
        otherPurpose
      }
    }
  }
}
`);

const UPDATE_ORGANIZATION_PROFILE = gql`
  mutation UpdateOrganizationProfile(
    $profileQuestionnaire: ProfileQuestionnaireInput
  ) {
    updateOrganizationProfile(profileQuestionnaire: $profileQuestionnaire) {
      organization {
        name
      }
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Dictionary = Record<string, any>;
function removeTypenameKey(arrayOfDictionaries: Dictionary[]): Dictionary[] {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return arrayOfDictionaries.map(({ __typename, ...rest }) => rest);
}

export default function OrganizationProfileQuestionnaire() {
  const { data } = useSuspenseQuery<OrganizationQuery>(GET_QUESTIONNAIRE, {
    fetchPolicy: 'network-only',
  });

  const questionnaire = data?.organization?.profileQuestionnaire;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formSchema: any = {
    countries: questionnaire?.countries || [],
    associatedPolicies: questionnaire?.associatedPolicies || [],
    valueNorms: questionnaire?.valueNorms || [
      { organizationValue: '', description: '' },
    ],
    externalContext: questionnaire?.externalContext || '',
    externalParties: questionnaire?.externalParties || [
      { name: '', requirements: '' },
    ],
    internalContext: questionnaire?.internalContext || '',
    contractualObligations: questionnaire?.contractualObligations || '',
    internalParties: questionnaire?.internalParties || [
      { name: '', requirements: '' },
    ],
    isClimateRelevant: questionnaire?.isClimateRelevant || false,
    objectives: questionnaire?.objectives || [
      { objectiveName: '', description: '', measure: '' },
    ],
    areObjectsMonitored: questionnaire?.areObjectsMonitored || false,
    areObjectsConsistent: questionnaire?.areObjectsConsistent || false,
    areObjectsCommunicated: questionnaire?.areObjectsCommunicated || false,
    areObjectsApplicable: questionnaire?.areObjectsApplicable || false,
    principles: questionnaire?.principles || [
      { principleName: '', description: '', measure: '' },
    ],
    systemScope: questionnaire?.systemScope || '',
    systemResources: questionnaire?.systemResources || '',
    risks: questionnaire?.risks || [],
    riskProfile: questionnaire?.riskProfile || '',
    prohibitedSystems: questionnaire?.prohibitedSystems || [
      { systemName: '', provider: '', reason: '' },
    ],
    opportunities: questionnaire?.opportunities || [],
    considerInternalExternal: questionnaire?.considerInternalExternal || false,
    considerRequirements: questionnaire?.considerRequirements || false,
    reviewPeriod: questionnaire?.reviewPeriod || 3,
    violationResponsible: questionnaire?.violationResponsible || '',
    aiStrategy: questionnaire?.aiStrategy || '',
    aiManagementSystem: questionnaire?.aiManagementSystem || {
      available: false,
      internalProcess: '',
    },
    aiUseCases: questionnaire?.aiUseCases || {
      developFinetune: false,
      selling: false,
      thirdParty: false,
      adaptingThirdParty: false,
    },
    definitionOfAi: questionnaire?.definitionOfAi || {
      predefinedDefinition: null,
      customDefinition: '',
    },
    purposeOfAiPolicy: questionnaire?.purposeOfAiPolicy || {
      outlineUseCases: false,
      governingDevelopment: false,
      promoteAiLiteracy: false,
      alignAiWithOrganisation: false,
      safeAiUsage: false,
      otherPurpose: '',
    },
  };
  const methods = useForm({ defaultValues: formSchema });

  const [updateOrganizationProfile] = useMutation(UPDATE_ORGANIZATION_PROFILE, {
    onCompleted: () => {
      toast.success('Organization profile saved successfully');
    },
    onError: error => {
      toast.error(`Error saving organization profile: ${error.message}`);
    },
  });

  function SaveButton() {
    const getValidEnumValue = (value: string) => (value === '' ? null : value);
    function handleSave() {
      updateOrganizationProfile({
        variables: {
          profileQuestionnaire: {
            countries: methods.getValues('countries'),
            associatedPolicies: methods.getValues('associatedPolicies'),
            otherPolicies: methods.getValues('otherPolicies'),
            valueNorms: removeTypenameKey(methods.getValues('valueNorms')),
            externalContext: methods.getValues('externalContext'),
            externalParties: removeTypenameKey(
              methods.getValues('externalParties')
            ),
            internalContext: methods.getValues('internalContext'),
            contractualObligations: methods.getValues('contractualObligations'),
            internalParties: removeTypenameKey(
              methods.getValues('internalParties')
            ),
            isClimateRelevant: methods.getValues('isClimateRelevant'),
            objectives: removeTypenameKey(
              methods.getValues('objectives')
            ).filter(dict => dict.objectiveName !== ''),
            principles: removeTypenameKey(
              methods.getValues('principles')
            ).filter(dict => dict.principleName !== ''),
            areObjectsApplicable: methods.getValues('areObjectsApplicable'),
            areObjectsCommunicated: methods.getValues('areObjectsCommunicated'),
            areObjectsConsistent: methods.getValues('areObjectsConsistent'),
            areObjectsMonitored: methods.getValues('areObjectsMonitored'),
            systemResources: methods.getValues('systemResources'),
            riskProfile: methods.getValues('riskProfile'),
            prohibitedSystems: methods.getValues('prohibitedSystems'),
            systemScope: methods.getValues('systemScope'),
            considerInternalExternal: methods.getValues(
              'considerInternalExternal'
            ),
            risks: methods.getValues('risks'),
            opportunities: methods.getValues('opportunities'),
            considerRequirements: methods.getValues('considerRequirements'),
            reviewPeriod: methods.getValues('reviewPeriod'),
            aiStrategy: methods.getValues('aiStrategy'),
            violationResponsible: methods.getValues('violationResponsible'),
            aiManagementSystem: methods.getValues('aiManagementSystem'),
            aiUseCases: methods.getValues('aiUseCases'),
            definitionOfAi: {
              predefinedDefinition: getValidEnumValue(
                methods.getValues('definitionOfAi.predefinedDefinition')
              ),
              customDefinition: methods.getValues(
                'definitionOfAi.customDefinition'
              ),
            },
            purposeOfAiPolicy: methods.getValues('purposeOfAiPolicy'),
          },
        },
      });
    }
    return (
      <Button variant="contained" onClick={handleSave}>
        Save
      </Button>
    );
  }

  const [value, setValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          overflowY: 'hidden',
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: '2rem' }}>
          Organization Profile
        </Typography>
        <TabContext value={value}>
          <Box sx={{ display: 'inline-block' }}>
            <TabList
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                background: colorThemes.DARK_BLUE_800,
              }}
            >
              <Tab
                label="External Context"
                {...a11yProps(0)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
              <Tab
                label="Internal Context"
                {...a11yProps(1)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
              <Tab
                label="Objectives & Principles"
                {...a11yProps(2)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
              <Tab
                label="Scope"
                {...a11yProps(3)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
              <Tab
                label="AI Policy"
                {...a11yProps(4)}
                sx={{ background: colorThemes.DARK_BLUE_800 }}
              />
            </TabList>
          </Box>
          <FormProvider {...methods}>
            <Box
              sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingX: '2rem',
                width: '100%',
                height: '80vh',
                paddingBottom: '3rem',
                marginTop: '1.5rem',
                ...scrollbarSx,
              }}
            >
              <CustomTabPanel value={value} index={0}>
                <ExternalContextStage />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <InternalContextStage />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <AIObjectivesStage />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <ScopeStage />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <AIPolicyStage />
              </CustomTabPanel>
            </Box>
          </FormProvider>
        </TabContext>
      </Box>
      <Box
        sx={{
          position: 'fixed',
          display: 'flex',
          top: '16px',
          right: '16px',
          gap: '1rem',
        }}
      >
        <Box sx={{ width: '150px' }}>
          <Controller
            name="reviewPeriod"
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth>
                <InputLabel>Review Period</InputLabel>
                <Select
                  labelId="simple-select-period"
                  label="Review Period"
                  value={value}
                  onChange={onChange}
                  size="small"
                >
                  {ReviewPeriodOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option} Months
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            control={methods.control}
            rules={{ required: true }}
          />
        </Box>
        <SaveButton />
      </Box>
      <ToastContainer />
    </>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const ReviewPeriodOptions = [3, 6, 9, 12];
