import {
  Box,
  Card,
  CardContent,
  DialogActions,
  DialogContentText,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import {
  gql,
  useApolloClient,
  useMutation,
  useSuspenseQuery,
} from '@apollo/client';
import {
  GetTuvRiskAssessmentsQuery,
  RiskAssessmentFrameworkFieldsFragment,
} from '../__generated__/gql/graphql';
import NewRiskAssessmentModal from './NewRiskAssessmentModal';
import { useState } from 'react';
import { colorThemes } from '../theme';
import RiskManagementDashboard from './dashboards/RiskManagementDashboard';
import CardGrid from '../components/CardGrid';
import { toast, ToastContainer } from 'react-toastify';
import { Button } from '@/components/ui/button';

const RISK_ASSESSMENT_FRAMEWORK_FIELDS = gql`
  fragment RiskAssessmentFrameworkFields on RiskAssessmentFrameworkMaterialized {
    id
    name
    checkPermission(
      targetQueryMutation: $targetQueryMutation
      action: $action
    ) {
      hasPermission
      userId
    }
  }
`;
const GET_TUV_RISK_ASSESSMENTS = gql`
  query getTuvRiskAssessments(
    $projectId: String!
    $targetQueryMutation: String!
    $action: String!
  ) {
    project(id: $projectId) {
      riskAssessmentFrameworks {
        ...RiskAssessmentFrameworkFields
      }
    }
  }
  ${RISK_ASSESSMENT_FRAMEWORK_FIELDS}
`;

const DELETE_RISK_ASSESSMENT = gql`
  mutation deleteProjectRiskAssessment(
    $projectId: ID!
    $riskAssessmentId: ID!
  ) {
    deleteRiskAssessment(
      projectId: $projectId
      riskAssessmentId: $riskAssessmentId
    ) {
      projectRiskAssessments {
        id
        name
      }
    }
  }
`;

export default function RiskManagement() {
  const { projectId } = useParams();
  const { data } = useSuspenseQuery<GetTuvRiskAssessmentsQuery>(
    GET_TUV_RISK_ASSESSMENTS,
    {
      variables: {
        projectId,
        targetQueryMutation: 'deleteRiskAssessment',
        action: 'mutation',
      },
    }
  );

  const riskAssessmentFrameworks =
    data?.project?.riskAssessmentFrameworks?.filter(
      (framework): framework is RiskAssessmentFrameworkFieldsFragment =>
        framework !== null
    ) ?? [];
  const [showRiskAssesmentModal, setShowRiskAssesmentModal] = useState(false);

  return (
    <>
      <Box display="flex" gap={2} flexDirection="column" width="100%" pb={2}>
        <Typography variant="h4" marginBottom={3}>
          Risk Assessments
        </Typography>
        <RiskManagementDashboard />

        <CardGrid
          items={riskAssessmentFrameworks}
          title={
            riskAssessmentFrameworks.length === 0
              ? 'No Frameworks'
              : riskAssessmentFrameworks.length > 1
              ? `${riskAssessmentFrameworks.length} Frameworks`
              : '1 Framework'
          }
          renderCard={assessment => (
            <AssesmentCard
              key={assessment.id}
              assessment={assessment}
              projectId={projectId}
              allAssessments={riskAssessmentFrameworks}
            />
          )}
          onAddNew={() => setShowRiskAssesmentModal(true)}
        />
        <NewRiskAssessmentModal
          open={showRiskAssesmentModal}
          onClose={() => setShowRiskAssesmentModal(false)}
        />
      </Box>
    </>
  );
}

interface AssessmentCardProps {
  assessment: RiskAssessmentFrameworkFieldsFragment;
  projectId?: string;
  allAssessments: RiskAssessmentFrameworkFieldsFragment[];
}

function AssesmentCard({ assessment, projectId }: AssessmentCardProps) {
  const navigate = useNavigate();
  const client = useApolloClient();
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteRiskAssessment] = useMutation(DELETE_RISK_ASSESSMENT, {
    variables: { projectId, riskAssessmentId: assessment.id },
  });

  const handleSelect = (name: string, frameworkId: string) => {
    navigate(`/risk_assessment/${projectId}/${name}/${frameworkId}/`);
  };

  const handleDelete = async () => {
    try {
      const updatedRiskAssessments = await deleteRiskAssessment();
      client.cache.updateQuery(
        {
          query: GET_TUV_RISK_ASSESSMENTS,
          variables: {
            projectId,
            targetQueryMutation: 'deleteRiskAssessment',
            action: 'mutation',
          },
        },
        dataCache => {
          return {
            ...dataCache,
            project: {
              ...dataCache.project,
              riskAssessmentFrameworks:
                updatedRiskAssessments.data.deleteRiskAssessment
                  .projectRiskAssessments,
            },
          };
        }
      );
      toast.success('Successfully deleted risk assessment');
    } catch (error) {
      toast.error('Failed to delete risk assessment');
      console.error('Error deleting risk assessment:', error);
    } finally {
      setDeleteConfirmOpen(false);
    }
  };

  return (
    <Card
      sx={{
        width: 400,
        height: 200,
        transition: 'all .1s',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid #334E68',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: colorThemes.DARK_BLUE_400,
        },
      }}
      onClick={() => handleSelect(assessment.name, assessment.id)}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {assessment.name} Risk Assessment
        </Typography>

        {assessment.checkPermission?.hasPermission && (
          <TrashIcon
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              margin: '5px',
              zIndex: 100,
            }}
            onClick={() => {
              setDeleteConfirmOpen(true);
            }}
          />
        )}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
        >
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this risk assessment framework?
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
            <Button onClick={handleDelete} variant="destructive">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <ToastContainer />
      </CardContent>
    </Card>
  );
}
