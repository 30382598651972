import { FC, useState, useCallback } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';
import { gql, useMutation } from '@apollo/client';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Card, CardContent } from '@/components/ui/card';
import { Badge } from '@/components/ui/badge';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Upload, FileText, FileIcon, Info, Eye, X, Plus } from 'lucide-react';
import { TrailLiteracyTrainingPreview } from '../../../__generated__/gql/graphql';
import PdfViewer from '../../../components/PdfViewer';
import PaywallModal from '../../../components/PaywallModal';
import { useFormContext } from 'react-hook-form';
import SectionUploadField from './SectionUploadField';
import { FormValues } from '../TrainingCreationModal';
import { Spinner } from '@/components/ui/spinner';

interface MaterialUploadStepProps {
  onFileSelected: (file: File | null) => void;
  onFileIdSelected: (fileId: string | null) => void;
  onExistingMaterialSelected: (materialId: string | null) => void;
  availableTrainingMaterials: TrailLiteracyTrainingPreview[];
  hasLiteracyAccess: boolean;
  selectedFile: File | null;
  selectedExistingMaterial: string | null;
}

const GET_PRESIGNED_URL = gql`
  mutation GetPresignedUploadUrl($fileName: String!, $contentType: String!) {
    getPresignedUploadUrl(fileName: $fileName, contentType: $contentType) {
      presignedUrl
      fileId
    }
  }
`;

const MaterialUploadStep: FC<MaterialUploadStepProps> = ({
  onFileSelected,
  onFileIdSelected,
  onExistingMaterialSelected,
  availableTrainingMaterials,
  hasLiteracyAccess,
  selectedFile,
  selectedExistingMaterial,
}) => {
  const [getPresignedUrl] = useMutation(GET_PRESIGNED_URL);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [pdfData, setPdfData] = useState<string | null>(null);
  const [showPaywall, setShowPaywall] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { watch, setValue } = useFormContext<FormValues>();
  const uploadMethod = watch('uploadMethod');
  const sections = watch('sections') || [];

  const handleUpgrade = () => {
    window.location.href = '/pricing';
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setIsUploading(true);
        try {
          const { data } = await getPresignedUrl({
            variables: {
              fileName: file.name,
              contentType: file.type,
            },
          });

          if (!data || !data.getPresignedUploadUrl) {
            throw new Error('Failed to get presigned URL');
          }

          const requestInit: RequestInit = {
            method: 'PUT',
            body: file,
            headers: { 'Content-Type': file.type },
          };

          const response = await fetch(
            data.getPresignedUploadUrl.presignedUrl,
            requestInit
          );

          if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to upload file: ${errorText}`);
          }

          onFileSelected(file);
          onFileIdSelected(data.getPresignedUploadUrl.fileId);
          onExistingMaterialSelected(null);
        } catch (error) {
          console.error('Error uploading file:', error);
          toast.error('Failed to upload file');
          onFileSelected(null);
          onFileIdSelected(null);
        } finally {
          setIsUploading(false);
        }
      }
    },
    [
      onFileSelected,
      onFileIdSelected,
      onExistingMaterialSelected,
      getPresignedUrl,
    ]
  );

  const handleRemoveFile = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      onFileSelected(null);
      onFileIdSelected(null);
    },
    [onFileSelected, onFileIdSelected]
  );

  const handleExistingMaterialChange = useCallback(
    (material: { id: string; url: string }) => {
      if (!hasLiteracyAccess) {
        setShowPaywall(true);
        return;
      }
      if (selectedExistingMaterial === material.id) {
        onExistingMaterialSelected(null);
      } else {
        onExistingMaterialSelected(material.id);
        onFileSelected(null);
      }
    },
    [
      hasLiteracyAccess,
      onExistingMaterialSelected,
      onFileSelected,
      selectedExistingMaterial,
    ]
  );

  const handlePreviewOpen = useCallback((pdfData: string) => {
    setPdfData(pdfData);
    setIsPreviewOpen(true);
  }, []);

  const handlePreviewClose = useCallback(() => {
    setIsPreviewOpen(false);
    setPdfData(null);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
    maxSize: 10485760, // 10MB
    disabled: uploadMethod !== 'new',
    onDropRejected: (rejectedFiles: FileRejection[]) => {
      console.log('Files rejected:', rejectedFiles);
      rejectedFiles.forEach(({ file, errors }) => {
        console.log('Rejected file details:', {
          name: file.name,
          type: file.type,
          size: file.size,
          errors: errors.map(e => ({
            code: e.code,
            message: e.message,
          })),
        });

        errors.forEach(error => {
          switch (error.code) {
            case 'file-too-large':
              toast.error(`${file.name} is too large. Max size is 10MB`);
              break;
            case 'file-invalid-type':
              toast.error(
                `${file.name} is not a valid PDF file (type: ${file.type})`
              );
              break;
            default:
              toast.error(`Error uploading ${file.name}: ${error.message}`);
          }
        });
      });
    },
  });

  const handleUploadMethodChange = (value: string) => {
    setValue('uploadMethod', value as 'new' | 'existing' | 'sections');
  };

  const addSection = () => {
    const newSection = { id: Date.now(), title: '', file: null, fileId: null };
    setValue('sections', [...sections, newSection]);
  };

  const updateSection = (updatedSection: {
    id: number;
    title: string;
    file: File | null;
    fileId: string | null;
  }) => {
    setValue(
      'sections',
      sections.map(sec => (sec.id === updatedSection.id ? updatedSection : sec))
    );
  };

  const removeSection = (id: number) => {
    setValue(
      'sections',
      sections.filter(sec => sec.id !== id)
    );
  };

  return (
    <>
      <div className="space-y-6 flex flex-col gap-6 h-full">
        <Tabs value={uploadMethod} onValueChange={handleUploadMethodChange}>
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="new" className="flex items-center gap-2">
              <Upload className="h-4 w-4" />
              Upload whole Training Material
            </TabsTrigger>
            <TabsTrigger value="sections" className="flex items-center gap-2">
              <FileIcon className="h-4 w-4" />
              Upload Training in Sections
            </TabsTrigger>
            <TabsTrigger value="existing" className="flex items-center gap-2">
              <FileText className="h-4 w-4" />
              Use Trail's Material
            </TabsTrigger>
          </TabsList>

          <div className="p-6">
            {uploadMethod === 'existing' ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {availableTrainingMaterials.length > 0 ? (
                  availableTrainingMaterials
                    .filter(
                      (
                        material
                      ): material is TrailLiteracyTrainingPreview & {
                        trainingMaterial: NonNullable<
                          TrailLiteracyTrainingPreview['trainingMaterial']
                        >;
                      } =>
                        Boolean(
                          material?.trainingMaterial?.id &&
                            material?.trainingMaterial?.url
                        )
                    )
                    .map(material => (
                      <Card
                        key={material.trainingMaterial.id}
                        className={`relative bg-dark-blue-200 transition-all hover:-translate-y-1 hover:shadow-lg cursor-pointer ${
                          selectedExistingMaterial ===
                          material.trainingMaterial.id
                            ? 'border-2 border-primary'
                            : ''
                        }`}
                      >
                        <CardContent
                          onClick={() => {
                            const trainingMaterial = material.trainingMaterial;
                            if (trainingMaterial?.id && trainingMaterial?.url) {
                              handleExistingMaterialChange({
                                id: trainingMaterial.id,
                                url: trainingMaterial.url,
                              });
                            }
                          }}
                          className="p-6"
                        >
                          <div className="flex justify-between items-start mb-4">
                            <h3 className="font-bold flex-grow mr-2">
                              {material.previewTitle}
                            </h3>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="p-1"
                              onClick={e => {
                                e.stopPropagation();
                                handlePreviewOpen(
                                  material.trainingMaterial.contentBase64 ?? ''
                                );
                              }}
                            >
                              <Eye className="h-4 w-4" />
                            </Button>
                          </div>

                          <p className="text-sm text-muted-foreground line-clamp-3 mb-4">
                            {material.description}
                          </p>
                          <Badge
                            variant="outline"
                            className="flex items-center gap-1 w-fit"
                          >
                            <FileIcon className="h-4 w-4" />
                            PDF
                          </Badge>
                        </CardContent>
                      </Card>
                    ))
                ) : (
                  <div className="col-span-full">
                    <div className="flex flex-col items-center justify-center p-8 border-2 border-dashed rounded-lg">
                      <FileText className="h-12 w-12 text-muted-foreground mb-4" />
                      <h3 className="text-lg font-semibold text-muted-foreground mb-2">
                        No Trail Training Materials Available
                      </h3>
                      <p className="text-sm text-muted-foreground text-center">
                        There are currently no existing training materials to
                        choose from. Please check back later.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : uploadMethod === 'new' ? (
              <div
                {...getRootProps()}
                className={`flex w-full border-2 border-dashed rounded-lg p-6 text-center transition-colors ${
                  isDragActive
                    ? 'bg-dark-blue-200'
                    : selectedFile
                    ? 'bg-dark-blue-200/50'
                    : ''
                } hover:bg-dark-blue-400 cursor-pointer min-h-46 justify-center items-center`}
              >
                <input {...getInputProps()} />
                <div className="flex flex-col items-center gap-2">
                  {isUploading ? (
                    <Spinner size="medium">
                      <span className="mt-2 text-sm text-muted-foreground">
                        Uploading file...
                      </span>
                    </Spinner>
                  ) : (
                    <>
                      <Upload className="h-8 w-8 text-muted-foreground" />
                      {selectedFile ? (
                        <div className="flex items-center gap-2">
                          <span>{selectedFile.name}</span>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={e => {
                              e.stopPropagation();
                              handleRemoveFile();
                            }}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ) : (
                        <p>
                          {isDragActive
                            ? 'Drop the file here...'
                            : 'Drag and drop training material, or click to select'}
                        </p>
                      )}
                      <div className="flex items-center gap-1">
                        <span className="text-sm text-muted-foreground">
                          Supported format: PDF (max 10MB)
                        </span>
                        <Info className="h-4 w-4 text-muted-foreground cursor-help" />
                      </div>
                      <p className="text-sm text-muted-foreground italic">
                        Please ensure your PDF is in horizontal orientation
                      </p>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                {sections.map(sec => (
                  <SectionUploadField
                    key={sec.id}
                    section={sec}
                    onChange={updateSection}
                    onRemove={removeSection}
                    getPresignedUrl={options => getPresignedUrl(options)}
                  />
                ))}
                <Button
                  onClick={addSection}
                  className="w-full h-12 mt-4 border-2 border-dashed hover:border-yellow-500"
                  variant="outline"
                >
                  <Plus className="w-5 h-5 mr-2" />
                  Add New Section
                </Button>
              </div>
            )}
          </div>
        </Tabs>
      </div>

      {/* Preview Dialog - only shown for Trail's Material */}
      {uploadMethod === 'existing' && (
        <Dialog open={isPreviewOpen} onOpenChange={handlePreviewClose}>
          <DialogContent className="sm:max-w-[800px]">
            <div className="h-[70vh] w-full">
              {pdfData && (
                <PdfViewer
                  pdfData={pdfData}
                  currentPage={1}
                  onPageLoad={(_totalPages: number) => {}}
                />
              )}
            </div>
          </DialogContent>
        </Dialog>
      )}

      {/* PaywallModal */}
      <PaywallModal
        open={showPaywall}
        onClose={() => setShowPaywall(false)}
        onAction={handleUpgrade}
        title="Access Pre-Made Training Templates"
        primaryText="Start Training Faster with Ready-Made Materials"
        secondaryText={
          "Unlock Trail's library of professional training templates and get immediate access to:\n\n" +
          '• Ready-to-use training materials\n' +
          '• Professionally curated content\n'
        }
        actionButtonText="Add Addon Now"
      />
    </>
  );
};

export default MaterialUploadStep;
